/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { FC, useEffect, useState } from 'react'
import { useCollection } from 'react-firebase-hooks/firestore'
import { getBlogsQuery } from '../../utils/queries'
import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router'
import { ROUTES } from '../../constants/routes'
import { Blog } from '../../models/Blog'
import { toast } from 'react-toastify'
import BlogsBanner from '../../assets/Blogs/blogs-banner.jpg'
import PureReactCarousel from '../../components/PureReactCarousel/ReactPureCarousel'
import { Slide } from 'pure-react-carousel'
import { getResponsiveNoOfSlides } from '../../utils/getResponsiveNoOfSildes'
import parser from 'html-react-parser'

const Blogs: FC = () => {
    const [blogs, setBlogs] = useState<Blog[]>([])
    const [selectedBlogIndex, setSelectedBlogIndex] = useState<number | undefined>()

    const { slug } = useParams<{ slug: string }>()
    const location = useLocation()
    const navigate = useNavigate()
    const q = getBlogsQuery()
    const [snapshot, loading, error] = useCollection(q, {
        snapshotListenOptions: { includeMetadataChanges: true }
    })

    const blogSelectionHandler = (blogIndex: number) => {
        setSelectedBlogIndex(blogIndex)
        const selectedBlog = blogs[blogIndex]
        navigate(`${ROUTES.BLOGS}/${selectedBlog.category}/${selectedBlog.slug}`)
    }

    useEffect(() => {
        if (!loading && error == null) {
            const blogsRes = snapshot?.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id
            })) as Blog[]
            if (slug) {
                let selectedBlogIndex
                blogsRes.forEach((b, i) => {
                    if (b.slug === slug) {
                        selectedBlogIndex = i
                        return true
                    }
                })
                if (selectedBlogIndex !== undefined) {
                    setSelectedBlogIndex(selectedBlogIndex)
                } else {
                    navigate(ROUTES.BLOGS)
                }
            }
            setBlogs([...blogsRes])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, snapshot])

    useEffect(() => {
        if (location.pathname === ROUTES.BLOGS) {
            setSelectedBlogIndex(undefined)
        }
    }, [location.pathname])

    const nextBlogHandler = () => {
        if (selectedBlogIndex! < blogs.length - 1) {
            setSelectedBlogIndex((p) => p! + 1)
        }
    }
    const prevBlogHandler = () => {
        if (selectedBlogIndex! > 0) {
            setSelectedBlogIndex((p) => p! - 1)
        }
    }

    const copyLinkHandler = () => {
        const selectedBlog = blogs[selectedBlogIndex!]
        const link = `${ROUTES.BLOGS}/${selectedBlog.category}/${selectedBlog.slug}`
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        navigator.clipboard.writeText(link)
        toast.success('Blog Link copied successfully')
    }

    const selectedBlog = typeof selectedBlogIndex === 'number' ? blogs[selectedBlogIndex] : undefined
    const { noOfSlidesForBlogs } = getResponsiveNoOfSlides()
    return (
        <Container sx={{ padding: '0 !important' }} maxWidth="xl">
            <Box sx={{ minHeight: '100vh' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '600px',
                        maxHeight: { xs: '300px', sm: '400px', md: '450px', lg: '500px', xl: '600px' },
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundImage: `url(${BlogsBanner})`,
                        padding: '20px',
                        backgroundPosition: 'center'
                    }}
                >
                    <Typography variant="h1" sx={{
                        background: '#ffffff29',
                        padding: '10px 20px',
                        border: '3px solid #ffffff61',
                        textAlign: 'center',
                        color: 'white',
                        fontSize: { xs: '30px', md: '50px', lg: '70px' },
                        fontWeight: 'bold'
                    }}>
                        {(selectedBlog != null) ? selectedBlog.title : 'BLOGS'}
                    </Typography>
                </Box>
                <Container maxWidth="xl" sx={{ padding: '24px !important' }}>
                    <Grid container spacing={2}>
                        <Grid order={{ xs: 2, md: 1 }} item xs={12} md={8}>
                            {(selectedBlog != null)
                                ? (
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                        <Box textAlign="end">
                                            <Button
                                                sx={{ border: 'none', color: 'black', '&:hover': { background: 'transparent' } }}
                                                onClick={prevBlogHandler}
                                                disabled={selectedBlogIndex! <= 0}
                                                type="button"
                                                variant="text"
                                            >
                                                {'< Previous'}
                                            </Button>
                                            <Button
                                                sx={{ border: 'none', color: 'black', '&:hover': { background: 'transparent' } }}
                                                onClick={nextBlogHandler}
                                                disabled={selectedBlogIndex! >= blogs.length - 1}
                                                type="button"
                                            >
                                                {'Next >'}
                                            </Button>
                                        </Box>
                                        <Box sx={{ alignSelf: 'start', display: 'flex', flexGrow: 1, flexDirection: 'column', gap: '20px' }}>
                                            <Box>
                                                <img style={{ maxWidth: '100%' }} src={selectedBlog.preview_url} />
                                            </Box>
                                            {selectedBlog.details ? <Box>{parser(selectedBlog.details)}</Box> : null}
                                            <Typography sx={{ background: '#f6f6f6', padding: '5px 20px' }}>
                                                <b>Share by</b>
                                                <Button onClick={copyLinkHandler}>Copying Link</Button>
                                            </Typography>
                                        </Box>
                                        {blogs.length > 1
                                            ? (
                                                <Box>
                                                    <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>Related Blogs</Typography>
                                                    <hr />
                                                    <PureReactCarousel CarouselProviderClassName="carousel-provider" totalSlides={3} visibleSlides={noOfSlidesForBlogs} showCarouselButtons={false}>
                                                        {blogs.map((b, i) => (
                                                            <Slide key={b.slug} index={i}>
                                                                <Box key={b.slug} sx={{ padding: '10px 20px', maxWidth: '100%', cursor: 'pointer' }} onClick={() => blogSelectionHandler(i)}>
                                                                    <img width="100%" style={{ maxHeight: '150px' }} src={b.preview_url} />
                                                                    <Typography variant="h6">{b.title}</Typography>
                                                                </Box>
                                                            </Slide>
                                                        ))}
                                                    </PureReactCarousel>
                                                </Box>
                                            )
                                            : null}
                                    </Box>
                                )
                                : (
                                    <Box sx={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
                                        {blogs.map((blog, i) => {
                                            let detailPreview = blog.details ? blog.details.replaceAll(/<\/?[^>]+(>|$)/g, '') : ''
                                            detailPreview = detailPreview.length > 100 ? `${detailPreview.slice(0, 100)}...` : detailPreview
                                            return (
                                                <Box key={blog.slug} sx={{ padding: '20px', maxWidth: '200px', cursor: 'pointer' }} onClick={() => blogSelectionHandler(i)}>
                                                    <img width={200} style={{ maxHeight: '175px' }} src={blog.preview_url} />
                                                    <Typography variant="h6">{blog.title}</Typography>
                                                    <Typography>{detailPreview}</Typography>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                )}
                        </Grid>
                        <Grid order={{ xs: 1, md: 2 }} item xs={12} md={4}>
                            <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column', textAlign: 'center', padding: '10px', boxShadow: '0px 0px 1px black' }}>
                                <Typography variant="h5">Latest Blogs</Typography>
                                {blogs.slice(0, 5).map((blog, i) => (
                                    <Typography
                                        onClick={() => blogSelectionHandler(i)}
                                        key={blog.slug}
                                        sx={{ cursor: 'pointer', padding: '5px 10px', color: '#0062FF', backgroundColor: 'white', border: '1px solid #0062FF', borderRadius: '5px' }}
                                    >
                                        {blog.title}
                                    </Typography>
                                ))}
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Container>
    )
}

export default Blogs
