import { FC, useEffect, useState } from 'react'
import {
    Typography,
    Box,
    CircularProgress
} from '@mui/material'
import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore'
import PropertyTable from '../PropertyTable'
import { Data } from '../types'
import { useCollection } from 'react-firebase-hooks/firestore'
import GroupsList from './GroupsList'
import { getGroupsQuery } from '../../../utils/queries'

interface Props {
    properties: Array<QueryDocumentSnapshot<DocumentData>> | undefined
    page: string
}

const PropertyList: FC<Props> = ({ properties, page }) => {
    const [rows, setRows] = useState<Data[]>([])
    const [groupId, setGroupId] = useState<string>('')
    const [groupName, setGroupName] = useState<string>('All Properties')
    const [groupsWithProperties, setGroupsWithProperties] = useState<string[]>([])
    const [selected, setSelected] = useState<string[]>([])

    const groupsQuery = getGroupsQuery()

    const [groups, groupsLoading] = useCollection(groupsQuery)

    useEffect(() => {
        let filteredRows = properties
        if (groupId !== '') {
            filteredRows = filteredRows?.filter(property => property.data().groups?.includes(groupId))
        }
        const newRows = filteredRows?.map(property => ({
            id: property.id,
            address: property.data().propertyInfo.streetAddress,
            cashFlow: property.data().cashFlowInfo.cashFlow ?? 0,
            breakeven: property.data().breakevenInfo.breakeven ?? 0,
            mortgage: property.data().mortgageInfo.mortgage ?? 0,
            ownership: property.data().ownershipInfo.ownership ?? 0,
            isDeleted: property.data().isDeleted ?? false
        })) ?? []
        setRows(newRows)
    }, [properties, groupId])

    useEffect(() => {
        const newGroupIds: Set<string> = new Set()
        properties?.forEach(property => property.data().groups?.forEach((group: string) => newGroupIds.add(group)))
        setGroupsWithProperties([...newGroupIds])
    }, [properties])

    return (
        <>
            {
                properties !== undefined && properties.length !== 0
                    ? (
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '10px'
                                }}
                            >
                                {
                                    groupsLoading
                                        ? (<CircularProgress color="primary" />)
                                        : <><GroupsList groups={groups?.docs?.filter(group => groupsWithProperties.includes(group.id)) ?? []} setGroupId={setGroupId} setGroupName={setGroupName} setSelected={setSelected} /></>
                                }

                                <PropertyTable rows={rows} groupName={groupName} groupId={groupId} selected={selected} setSelected={setSelected} page={page} />
                            </Box>
                        </>
                    )
                    : (
                        <>
                            <Typography
                                variant="h2"
                                sx={{
                                    fontWeight: '600',
                                    fontSize: { lg: '24px', md: '20px', xs: '18px' },
                                    textAlign: 'center',
                                    lineHeight: '36px',
                                    letterSpacing: '0.1px',
                                    marginBottom: { lg: '30px', xs: '15px' }
                                }}
                            >
                                No Properties

                            </Typography>
                        </>
                    )
            }
        </>
    )
}

export default PropertyList
