import { FC, ReactNode } from 'react'
import { Box } from '@mui/material'
import Footer from '../Footer'
import Header from '../Header'

interface AppContentWrapperProps {
    children: ReactNode
}

const AppContentWrapper: FC<AppContentWrapperProps> = ({ children }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header />
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>{children}</Box>
            <Footer />
        </Box>
    )
}
export default AppContentWrapper
