import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { UseFormReturn } from 'react-hook-form'
import BreakevenFields from '../../Calculators/Breakeven/BreakevenFields'
import { BreakevenInput } from '../../Calculators/Breakeven/schema'

interface Props {
    methods: UseFormReturn<BreakevenInput>
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const BreakevenForm: FC<Props> = ({ methods, ...restProps }) => {
    return (
        <Box
            id="breakevenInfo"
        >
            <Typography
                variant="h3"
                sx={{
                    fontWeight: '600',
                    fontSize: { lg: '24px', md: '20px', xs: '18px' },
                    marginTop: '10px'
                }}
            >
                Breakeven
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '40px',
                    gap: '30px'
                }}
            >
                <BreakevenFields methods={methods} {...restProps} />
            </Box>
        </Box >
    )
}

export default BreakevenForm
