import { BreakevenInput } from '../components/Calculators/Breakeven/schema'
import { CashFlowInput } from '../components/Calculators/CashFlow/schema'
import { MortgageInput } from '../components/Calculators/Mortgage/schema'
import { OwnershipInput } from '../components/Calculators/Ownersip/schema'

export const numberToPercent = (value: number): number => {
    return value / 100
}

export const percentToNumberString = (value: number): string => {
    return (value * 100).toString()
}

export const convertNonNumericToZero = (obj: any) => {
    const filteredObject: any = {}
    Object.keys(obj).forEach(k => {
        const value = obj[k]
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        filteredObject[k] = Number(value) || 0
    })

    return filteredObject
}

export const getCashFlow = (values: CashFlowInput) => {
    const price = +values.salesPrice
    const downPmt = +values.downPayment
    const closing = +values.closingCosts
    const term = +values.mortgageTerm * 12
    const rate = +values.interestRate / 100 / 12
    const pmi = +values.monthlyPMI
    const taxes = +values.annualPropertyTaxes / 12
    const ins = +values.annualPropertyInsurance / 12
    const maint = +values.annualMaintenance / 12
    const hoa = +values.monthlyHOADues
    const util = +values.monthlyUtilities
    const propMgmt = +values.monthlyManagementFees
    const vacancy = +values.vacancyRate / 365
    const rent = +values.monthlyRent

    const loanAmt = price - downPmt + closing

    const payment =
        (loanAmt * (rate * Math.pow(1 + rate, term))) /
        (Math.pow(1 + rate, term) - 1)

    const weightedIncome = rent - rent * (vacancy / 365)

    const fixedCosts =
        pmi +
        taxes +
        ins +
        maint +
        hoa +
        util +
        propMgmt

    const result = weightedIncome - payment - fixedCosts
    const roundResult = Math.ceil(result)
    return roundResult.toString()
}

export const getBreakeven = (values: BreakevenInput) => {
    const downPmt = +values.downPayment
    const closing = +values.closingCosts
    const term = +values.mortgageTerm * 12
    const rate = +values.interestRate / 100 / 12
    const pmi = +values.monthlyPMI
    const taxes = +values.annualPropertyTaxes / 12
    const ins = +values.annualPropertyInsurance / 12
    const maint = +values.annualMaintenance / 12
    const hoa = +values.monthlyHOADues
    const util = +values.monthlyUtilities
    const propMgmt = +values.monthlyManagementFees
    const vacancy = +values.vacancyRate / 365
    const rent = +values.monthlyRent
    const profit = +values.desiredCF

    const weightedIncome = rent - rent * (vacancy / 365)

    const fixedCosts =
        pmi +
        taxes +
        ins +
        maint +
        hoa +
        util +
        propMgmt

    const loan =
        (weightedIncome - profit - fixedCosts) /
        ((rate * Math.pow(1 + rate, term)) /
            (Math.pow(1 + rate, term) - 1))

    const result = loan + downPmt - closing

    const roundResult = Math.ceil(result)

    return roundResult.toString()
}

export const getMortage = (values: MortgageInput) => {
    const price = +values.salesPrice
    const downPmt = +values.downPayment
    const closing = +values.closingCosts
    const term = +values.mortgageTerm * 12
    const rate = +values.interestRate / 100 / 12
    const pmi = +values.monthlyPMI

    const loanAmt = price - downPmt + closing

    const payment =
        (loanAmt * (rate * Math.pow(1 + rate, term))) /
        (Math.pow(1 + rate, term) - 1)

    const result = payment

    const roundResult = Math.ceil(result)

    return roundResult.toString()
}

export const getOwnership = (values: OwnershipInput) => {
    const price = +values.salesPrice
    const downPmt = +values.downPayment
    const closing = +values.closingCosts
    const term = +values.mortgageTerm * 12
    const rate = +values.interestRate / 100 / 12
    const pmi = +values.monthlyPMI
    const taxes = +values.annualPropertyTaxes / 12
    const ins = +values.annualPropertyInsurance / 12
    const maint = +values.annualMaintenance / 12
    const hoa = +values.monthlyHOADues

    const loanAmt = price - downPmt + closing

    const payment =
        (loanAmt * (rate * Math.pow(1 + rate, term))) /
        (Math.pow(1 + rate, term) - 1)

    const result = payment +
        pmi +
        taxes +
        ins +
        maint +
        hoa

    const roundResult = Math.ceil(result)

    return roundResult.toString()
}
