import {
    Box,
    Button,
    Divider,
    List,
    ListItem,
    Modal,
    SxProps,
    TextField,
    Typography
} from '@mui/material'
import { useState, FC, Dispatch, SetStateAction, useEffect } from 'react'
import ShareIcon from '@mui/icons-material/Share'
import { toast } from 'react-toastify'
import { isValidEmail } from '../../../../utils/email'
import { shareProperties } from '../../../../utils/property'
import { Data } from '../../types'

interface SharePropertyModalProps {
    selectedData: Data[]
    setSelected: Dispatch<SetStateAction<string[]>>
    // address: string
    // state: string
    // ROI: number
    userName: string
}

const MODAL_STYLE: SxProps = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 3,
    p: 2,
    outline: 0
}

const SharePropertyModal: FC<SharePropertyModalProps> = ({
    selectedData,
    setSelected,
    // address,
    // state,
    // ROI,
    userName
}) => {
    const [email, setEmail] = useState<string>('')
    const [open, setOpen] = useState<boolean>(false)
    const [subject, setSubject] = useState<string>('')
    const [message, setMessage] = useState<string>('')
    const [error, setError] = useState<boolean>(false)

    const closeModal = (): void => {
        setEmail('')
        setSubject('')
        setMessage('')
        setOpen(false)
    }

    const shareHandler = (): void => {
        handlePropertyShare().then(() => { }).catch((e) => { })
        setSelected([])
    }

    const handlePropertyShare = async (): Promise<void> => {
        if (isValidEmail(email)) {
            setError(false)
            try {
                await shareProperties(email, subject, message, selectedData.map(select => select.id))
                toast.success('Property successfully shared')
            } catch (err) {
                console.log(err)
            }
            closeModal()
        } else {
            setError(true)
        }
    }

    useEffect(() => {
        setSubject(`Take a Look at [${selectedData.map(select => select.address).join(', ')}]`)
    }, [selectedData])

    useEffect(() => {
        setMessage(`${userName !== '' ? userName : 'someone'} wants to share with you this property details\n${selectedData.map(select => `- ${select.address}`).join('\n')}`)
    }, [userName, selectedData])

    return (
        <>
            <Button
                onClick={() => setOpen(true)}
                sx={{
                    height: '30px',
                    fontSize: { lg: '14px', sm: '10px' },
                    letterSpacing: '0.1px',
                    backgroundColor: '#0062FF',
                    borderRadius: '10px',
                    textTransform: 'none'
                }}
                variant="contained"
                startIcon={<ShareIcon sx={{ fontSize: { lg: '12px', sm: '8px' } }} />}
            >
                Share
            </Button>
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={MODAL_STYLE}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                            mb: 4,
                            mt: 0,
                            fontWeight: 'bold'
                        }}
                    >
                        Share Properties with user
                    </Typography>
                    <Divider />
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Email:
                    </Typography>
                    <TextField
                        InputProps={{
                            style: {
                                padding: '8px'
                            }
                        }}
                        inputProps={{
                            style: {
                                fontSize: '14px',
                                padding: '0px'
                            }
                        }}
                        type="email"
                        placeholder="abc@example.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                        Title:
                    </Typography>
                    <TextField
                        sx={{
                            width: '100%'
                        }}
                        InputProps={{
                            style: {
                                padding: '8px'
                            }
                        }}
                        inputProps={{
                            style: {
                                fontSize: '14px',
                                padding: '0px'
                            }
                        }}
                        type="text"
                        placeholder="Property view invitation"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                    />
                    <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                        Message:
                    </Typography>
                    <TextField
                        InputProps={{
                            style: {
                                padding: '2px 6px'
                            }
                        }}
                        sx={{
                            width: '100%'
                        }}
                        inputProps={{
                            style: {
                                fontSize: '14px'
                            }
                        }}
                        multiline
                        rows={4}
                        type="text"
                        placeholder="some custom message to the receiver"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    {error && (
                        <Typography
                            sx={{
                                mt: 1,
                                fontSize: '12px'
                            }}
                            color="red"
                        >
                            Please enter valid email
                        </Typography>
                    )}
                    <Divider sx={{
                        my: 2
                    }} />
                    <Box>
                        <Typography component='p' fontSize='large' fontWeight='bold'>
                            The Person You are Sharing With :
                        </Typography>
                        <List disablePadding>
                            <ListItem disablePadding sx={{ fontSize: '16px' }}>1- Will be able to View and Edit the details of these Properties and update their analysis.</ListItem>
                            <ListItem disablePadding sx={{ fontSize: '16px' }}>2- Will be able to Share these Properties with others.</ListItem>
                            <ListItem disablePadding sx={{ fontSize: '16px' }}>3- Will Be Not Be Able to View, Edit or Share Properties You did Not Share with them.</ListItem>
                        </List>
                    </Box>
                    <Divider
                        sx={{
                            mt: 4
                        }}
                    />
                    <Box
                        sx={{
                            mt: 4,
                            display: 'flex',
                            justifyContent: 'right'
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                marginRight: '12px'
                            }}
                            onClick={shareHandler}
                            color="info"
                        >
                            Share
                        </Button>
                        <Button variant="contained" color="error" onClick={closeModal}>
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default SharePropertyModal
