import { Typography, TextField, MenuItem, Box, Select, InputLabel, FormControl } from '@mui/material'
import { FC } from 'react'
import { STATES } from '../../../constants/forms'
import { FormProvider, SubmitHandler, UseFormHandleSubmit, UseFormReturn } from 'react-hook-form'
import { PropertyInfoInput } from './schema'
import { FormInput } from './FormInput'

interface Props {
    methods: UseFormReturn<PropertyInfoInput>
    handleSubmit?: UseFormHandleSubmit<PropertyInfoInput>
    onSubmitHandler?: SubmitHandler<PropertyInfoInput>
}

const PropertyInfoForm: FC<Props> = ({ methods, handleSubmit, onSubmitHandler }) => {
    const submitHandler = Boolean(handleSubmit) && Boolean(onSubmitHandler) ? handleSubmit!(onSubmitHandler!) : () => {}
    return (
        <Box
            id="propertyInfo"
        >
            <Typography
                variant="h3"
                sx={{
                    fontWeight: '600',
                    fontSize: { lg: '24px', md: '20px', xs: '18px' },
                    marginTop: '10px'
                }}
            >
                Property Information
            </Typography>
            <FormProvider {...methods}>
                <Box
                    component='form'
                    noValidate
                    autoComplete='off'
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onSubmit={submitHandler}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: '40px',
                        gap: '30px'
                    }}
                >
                    <FormInput
                        name='streetAddress'
                        required
                        label="Street Address"
                        sx={{ width: '300px' }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '15px'
                        }}
                    >
                        <FormInput
                            name='city'
                            required
                            label="City"
                            sx={{ width: '220px' }}
                        />
                        <FormControl>
                            <InputLabel id="state-label">State</InputLabel>
                            <FormInput
                                name='state'
                                required
                                sx={{ width: '220px' }}
                            >

                                <Select
                                    required
                                    labelId='state-label'
                                    label='State'
                                    sx={{ width: '220px' }}
                                    onChange={e =>
                                        methods.setValue('state', e.target.value as string)}
                                    MenuProps={{
                                        sx: {
                                            maxHeight: 400
                                        }
                                    }}
                                >
                                    {
                                        STATES.map(option => (
                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormInput>

                        </FormControl>
                        <FormInput
                            name='zipCode'
                            required
                            label="Zip Code"
                            sx={{ width: '150px' }}
                        />
                    </Box>

                </Box>
            </FormProvider>
        </Box >
    )
}

export default PropertyInfoForm
