import {
    Box,
    Button,
    Divider,
    Modal,
    SxProps,
    TextField,
    Typography
} from '@mui/material'
import { useState, FC } from 'react'
import ShareIcon from '@mui/icons-material/Share'
import { toast } from 'react-toastify'
import { isValidEmail } from '../../../../utils/email'
import { sharePropertyGroups } from '../../../../utils/property'

interface ShareGroupModalProps {
    groupId: string
    rowIds: string[]
}

const MODAL_STYLE: SxProps = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 3,
    p: 2,
    outline: 0
}

const ShareGroupModal: FC<ShareGroupModalProps> = ({ groupId, rowIds }) => {
    const [email, setEmail] = useState<string>('')
    const [open, setOpen] = useState<boolean>(false)
    const [subject, setSubject] = useState<string>('')
    const [message, setMessage] = useState<string>('')
    const [error, setError] = useState<boolean>(false)

    const closeModal = (): void => {
        setEmail('')
        setSubject('')
        setMessage('')
        setOpen(false)
    }

    const shareHandler = (): void => {
        handlePropertyShare().then(() => { }).catch((e) => { })
    }

    const handlePropertyShare = async (): Promise<void> => {
        if (isValidEmail(email)) {
            setError(false)
            try {
                await sharePropertyGroups(email, subject, message, rowIds, groupId)
                toast.success('Group successfully shared')
                closeModal()
            } catch (err) {
                console.log(err)
            }
        } else {
            setError(true)
        }
    }

    return (
        <>
            <Button
                onClick={() => setOpen(true)}
                sx={{
                    height: '30px',
                    fontSize: { lg: '14px', sm: '10px' },
                    letterSpacing: '0.1px',
                    backgroundColor: '#0062FF',
                    borderRadius: '10px',
                    textTransform: 'none'
                }}
                variant="contained"
                startIcon={<ShareIcon sx={{ fontSize: { lg: '12px', sm: '8px' } }} />}
            >
                Share Group
            </Button>
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={MODAL_STYLE}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                            mb: 4,
                            mt: 0,
                            fontWeight: 'bold'
                        }}
                    >
                        Share Group with user
                    </Typography>
                    <Divider />
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Email:
                    </Typography>
                    <TextField
                        sx={{
                            fontSize: '12px'
                        }}
                        inputProps={{
                            style: {
                                paddingBottom: '6px',
                                paddingTop: '6px'
                            }
                        }}
                        type="email"
                        placeholder="abc@example.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                        Title:
                    </Typography>
                    <TextField
                        sx={{
                            fontSize: '12px',
                            width: '100%'
                        }}
                        inputProps={{
                            style: {
                                paddingBottom: '6px',
                                paddingTop: '6px'
                            }
                        }}
                        type="email"
                        placeholder="Group view invitation"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                    />
                    <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                        Message:
                    </Typography>
                    <TextField
                        sx={{
                            fontSize: '12px',
                            width: '100%'
                        }}
                        inputProps={{
                            style: {
                                paddingBottom: '6px',
                                paddingTop: '6px'
                            }
                        }}
                        multiline
                        rows={4}
                        type="email"
                        placeholder="some custom message to the receiver"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    {error && (
                        <Typography
                            sx={{
                                mt: 1,
                                fontSize: '12px'
                            }}
                            color="red"
                        >
                            Please enter valid email
                        </Typography>
                    )}
                    <Divider
                        sx={{
                            mt: 4
                        }}
                    />
                    <Box
                        sx={{
                            mt: 4,
                            display: 'flex',
                            justifyContent: 'right'
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                marginRight: '12px'
                            }}
                            onClick={shareHandler}
                            color="info"
                        >
                            Share
                        </Button>
                        <Button variant="contained" color="error" onClick={closeModal}>
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default ShareGroupModal
