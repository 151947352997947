import { FC } from 'react'

import { Box, useMediaQuery } from '@mui/material'
import LeftNavbar from '../LeftNavbar'
import theme from '../../theme'
import { Outlet } from 'react-router-dom'

const Navigation: FC = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <>
            {!isMobile
                ? (
                    <Box
                        sx={{
                            backgroundColor: '#DFDFDF',
                            position: 'relative'
                        }}
                    >
                        <Box sx={{ display: 'flex', minHeight: screen.height - 70 }}>
                            <LeftNavbar />
                            <Box sx={{ overflow: 'hidden', padding: '20px', flexGrow: 1, display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                <Outlet />
                            </Box>
                        </Box>
                    </Box>
                )
                : (
                    <Box sx={{ minHeight: 'calc(100vh - 60px)', display: 'flex', flexDirection: 'column' }}>
                        <Outlet />
                    </Box>
                )
            }
        </>
    )
}

export default Navigation
