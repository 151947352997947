import { FC, useEffect, useState } from 'react'
import { Routes, Route } from 'react-router-dom'

// Importing Custom Loader Component
import CustomLoader from '../components/CustomLoader'

// Importing the useAuthState hook
import { useAuthState } from 'react-firebase-hooks/auth'
// Imoprting auth from firebase
import { auth } from '../firebase'
import { ROUTES } from '../constants/routes'
import { AuthStateHookType } from '../types/hooks'

// importing pages
import Login from '../pages/Login'
import Navigation from '../components/Navigation'
import Properties from '../pages/Properties'
import PropertyForm from '../pages/Properties/PropertyForm'
import NotFound from '../pages/NotFound'
import SignUp from '../pages/Signup'
import PropertyAnalysis from '../pages/PropertyAnalysis'
import Analysis from '../pages/PropertyAnalysis/Analysis'
import Support from '../pages/Support'
import { getSurveysQuery } from '../utils/queries'
import SurveyModal from '../components/SurveyModal'
import { getDocs } from '@firebase/firestore'
import Calculators from '../components/Calculators'
import NonPrivateRoutes from './NonPrivateRoutes'
import PrivateRoutes from './PrivateRoutes'
import Services from '../pages/Services'
import Blogs from '../pages/Blogs'
import AppContentWrapper from '../components/AppWrapper/AppContentWrapper'

const AppRouter: FC = () => {
    const [user, userLoading]: AuthStateHookType = useAuthState(auth)
    const [activeSurveys, setActiveSurveys] = useState<any[]>([])

    useEffect(() => {
        if (user === undefined || user === null) return

        getDocs(getSurveysQuery()).then(surveys => {
            const newSurveys = surveys.docs.filter(doc => !((doc.data().usersAttempted as string[])?.includes(user.uid) ?? false))
            setActiveSurveys(newSurveys)
        }).catch(err => console.log(err))
    }, [user])

    const isAuthenticated = Boolean(user)

    if (userLoading) {
        return <CustomLoader />
    } else {
        return (
            <>
                {
                    activeSurveys.length !== 0 &&
                    user !== undefined && user !== null &&
                    activeSurveys.map((doc, idx) => (
                        <SurveyModal key={doc.id} survey={doc} uid={user.uid} zIndex={`${idx + 1}`} />
                    ))
                }
                <AppContentWrapper>
                    <Routes>
                        <Route element={<NonPrivateRoutes isAuthenticated={isAuthenticated} />}>
                            <Route path={ROUTES.LOGIN} element={<Login />} />
                            <Route path={ROUTES.SIGNUP} element={<SignUp />} />
                        </Route>
                        <Route element={<PrivateRoutes isAuthenticated={isAuthenticated} />}>
                            <Route path={ROUTES.OVERVIEW} element={<Navigation />}>
                                <Route index element={<Calculators />} />
                                <Route path={ROUTES.CALCULATORS} element={<Calculators />} />
                                <Route path={ROUTES.PROPERTIES} element={<Properties />} />
                                <Route path={ROUTES.PROPERTY_FORM} element={<PropertyForm />} />
                                <Route path={ROUTES.PROPERTY_ANALYSIS} element={<PropertyAnalysis />} />
                                <Route path={ROUTES.ANALYSIS} element={<Analysis />} />
                                <Route path={ROUTES.PROFILE} element={<>Profile</>} />
                                <Route path={ROUTES.SUPPORT} element={<Support />} />
                            </Route>
                        </Route>
                        <Route path={ROUTES.SERVICES} element={<Services />} />
                        <Route path={ROUTES.SERVICE_DETAIL} element={<Services />} />
                        <Route path={ROUTES.BLOGS} element={<Blogs />} />
                        <Route path={ROUTES.BLOGS_DETAILS} element={<Blogs />} />
                        <Route path={ROUTES.NOT_FOUND} element={<NotFound />} />
                    </Routes>
                </AppContentWrapper>
            </>
        )
    }
}
export default AppRouter
