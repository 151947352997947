import {
    collection,
    orderBy,
    query,
    Query,
    QueryConstraint,
    where
} from 'firebase/firestore'
import { COLLECTION_NAMES } from '../constants/firestore'
import { auth, db } from '../firebase'

export const getQueryConstraintsForUser = (): QueryConstraint[] => {
    const queryConstraints: QueryConstraint[] = []

    if (auth.currentUser !== null) {
        if (auth.currentUser.isAnonymous) {
            queryConstraints.push(where('uid', '==', auth.currentUser.uid))
        } else {
            queryConstraints.push(
                where('sharedWith', 'array-contains', auth.currentUser.email)
            )
        }
    }

    return queryConstraints
}

export const getPropertiesQuery = (): Query => {
    const queryConstraints = getQueryConstraintsForUser()

    const propertiesQuery = query(
        collection(db, COLLECTION_NAMES.PROPERTIES_COLLECTION),
        ...queryConstraints
    )

    return propertiesQuery
}

export const getPropertiesAnalysisQuery = (): Query => {
    const queryConstraints = getQueryConstraintsForUser()

    const propertiesQuery = query(
        collection(db, COLLECTION_NAMES.PROPERTY_ANALYSIS_COLLECTION),
        ...queryConstraints
    )

    return propertiesQuery
}

export const getGroupsQuery = (): Query => {
    const queryConstraints = getQueryConstraintsForUser()

    const propertiesQuery = query(
        collection(db, COLLECTION_NAMES.GROUPS_COLLECTION),
        ...queryConstraints
    )

    return propertiesQuery
}

export const getNotificationsQuery = (): Query => {
    return query(
        collection(db, COLLECTION_NAMES.NOTIFICATIONS_COLLECTION),
        where('active', '==', true)
    )
}

export const getSurveysQuery = (): Query => {
    return query(
        collection(db, COLLECTION_NAMES.SURVEYS_COLLECTION),
        where('active', '==', true)
    )
}

export const getServicesQuery = (): Query => {
    return query(collection(db, COLLECTION_NAMES.SERVICES), orderBy('order'))
}

export const getServiceBySlugQuery = (serviceSlug: string): Query => {
    return query(collection(db, COLLECTION_NAMES.SERVICES), where('slug', '==', serviceSlug), orderBy('order'))
}

export const getBlogsQuery = (): Query => {
    return query(collection(db, COLLECTION_NAMES.BLOGS), orderBy('createdAt', 'desc'))
}

export const getBlogBySlugQuery = (blogSlug: string): Query => {
    return query(collection(db, COLLECTION_NAMES.BLOGS), where('slug', '==', blogSlug))
}
