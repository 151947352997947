import { Box, Typography } from '@mui/material'
import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useDocumentData } from 'react-firebase-hooks/firestore'
import { doc } from 'firebase/firestore'
import { db } from '../../../firebase'
import { COLLECTION_NAMES } from '../../../constants/firestore'
import { ROUTES } from '../../../constants/routes'
import CustomLoader from '../../../components/CustomLoader'
// type Props = {}

const Analysis: FC = () => {
    const { id } = useParams()

    const navigate = useNavigate()

    const propertyRef = doc(db, COLLECTION_NAMES.PROPERTY_ANALYSIS_COLLECTION, id as string)

    const [propertyData, propertyDataLoading, propertyDataError] = useDocumentData(
        propertyRef
        , {
            snapshotListenOptions: { includeMetadataChanges: true }
        }
    )

    useEffect(() => {
        if (propertyDataLoading) return
        if (propertyDataError !== undefined || propertyData === undefined) navigate(ROUTES.OVERVIEW)
    }, [propertyData, propertyDataError, propertyDataLoading, navigate])

    return (
        <>
            {
                propertyDataLoading && <CustomLoader />
            }
            {
                !propertyDataLoading && propertyDataError === undefined && <Box sx={{ display: 'flex', flexDirection: 'column', overflowX: 'auto' }}>
                    <Typography
                        variant="h2"
                        sx={{
                            fontWeight: '600',
                            fontSize: { lg: '24px', md: '20px', xs: '18px' },
                            lineHeight: '36px',
                            letterSpacing: '0.1px',
                            marginBottom: { lg: '30px', xs: '15px' }
                        }}
                    >
                        Property Analysis
                    </Typography>
                    <Box
                        component='pre'
                    >
                        {JSON.stringify(propertyData, null, 4)}
                    </Box>
                </Box>
            }

        </>
    )
}

export default Analysis
