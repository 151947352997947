import {
    createContext,
    ReactNode,
    useState,
    FC,
    Dispatch,
    SetStateAction
} from 'react'

interface AnonymousJobsType {
    hasAnonymousJobs: boolean
    anonymousJobs: string[]
    setAnonymousJobs: Dispatch<SetStateAction<string[]>>
    setHasAnonymousJobs: Dispatch<SetStateAction<boolean>>
}

const AnonymousJobsCTX = createContext<AnonymousJobsType>({
    hasAnonymousJobs: false,
    anonymousJobs: [],
    setAnonymousJobs: () => { },
    setHasAnonymousJobs: () => { }
})

const AnonymousJobsCTXProvider: FC<{ children: ReactNode }> = ({
    children
}) => {
    const [anonymousJobs, setAnonymousJobs] = useState<any[]>([])
    const [hasAnonymousJobs, setHasAnonymousJobs] = useState<boolean>(false)

    return (
        <AnonymousJobsCTX.Provider
            value={{
                anonymousJobs,
                setAnonymousJobs,
                hasAnonymousJobs,
                setHasAnonymousJobs
            }}
        >
            {children}
        </AnonymousJobsCTX.Provider>
    )
}

export { AnonymousJobsCTX, AnonymousJobsCTXProvider as default }
