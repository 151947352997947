import { addDoc, collection } from 'firebase/firestore'
import { emailTemplate } from '../constants/emailTemplate'
import { COLLECTION_NAMES } from '../constants/firestore'
import { db } from '../firebase'

export const isValidEmail = (email: string): boolean => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    )
}

export const sendShareEmail = async (email: string, subject: string, message: string): Promise<void> => {
    let html = emailTemplate.replace('{link}', window.location.href)
    html = emailTemplate.replace(
        '{message}',
        message.length !== 0
            ? message
            : 'You have been invited to view a property at Nest Swift.'
    )
    const colRef = collection(db, COLLECTION_NAMES.MAIL_COLLECTION)
    await addDoc(colRef, {
        to: email,
        message: {
            subject: subject.length !== 0 ? subject : 'Property view invitation!',
            html
        }
    })
}
