import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { UseFormReturn } from 'react-hook-form'
import { MortgageInput } from '../../Calculators/Mortgage/schema'
import MortageFields from '../../Calculators/Mortgage/MortageFields'

interface Props {
    methods: UseFormReturn<MortgageInput>
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const MortageForm: FC<Props> = ({ methods, ...restProps }) => {
    return (
        <Box
            id="mortgageInfo"
        >
            <Typography
                variant="h3"
                sx={{
                    fontWeight: '600',
                    fontSize: { lg: '24px', md: '20px', xs: '18px' },
                    marginTop: '10px'
                }}
            >
                Mortage
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '40px',
                    gap: '30px'
                }}
            >
                <MortageFields methods={methods} {...restProps} />
            </Box>
        </Box >
    )
}

export default MortageForm
