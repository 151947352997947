import { Box, Grid } from '@mui/material'
import { CalculatorFormInput } from '../FormInput'
import { FormProvider, SubmitHandler, UseFormHandleSubmit, UseFormReturn } from 'react-hook-form'
import { FC, ReactNode } from 'react'
import { MortgageInput } from './schema'
import { mortgageFields } from '../../../constants/calculator'

interface Props {
    methods: UseFormReturn<MortgageInput>
    handleSubmit?: UseFormHandleSubmit<MortgageInput>
    onSubmitHandler?: SubmitHandler<MortgageInput>
    applyGrid?: boolean
    children?: ReactNode
}

const MortageFields: FC<Props> = ({ methods, applyGrid = true, handleSubmit, onSubmitHandler, children, ...otherProps }) => {
    const submitHandler = Boolean(handleSubmit) && Boolean(onSubmitHandler) ? handleSubmit!(onSubmitHandler!) : () => {}
    return (
        <FormProvider {...methods}>
            <Box
                component='form'
                noValidate
                autoComplete='off'
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onSubmit={submitHandler}
            >
                <Grid overflow='hidden' container spacing={2}>
                    {mortgageFields.map(({ label, name }) => (
                        <Grid key={name} item {...applyGrid ? { xs: 4, lg: 3, xl: 2 } : { xs: 12 }}>
                            <CalculatorFormInput
                                name={name}
                                required
                                fullWidth
                                label={label}
                                {...otherProps}
                            />
                        </Grid>
                    ))}
                </Grid>
                {children}
            </Box>
        </FormProvider>
    )
}

export default MortageFields
