import { Close, Done } from '@mui/icons-material'
import { Box, Typography, Link } from '@mui/material'
import { FC } from 'react'

interface Props {
    isCompleted: boolean
    text: string
    id: string
}

const CompletedInfo: FC<Props> = ({ isCompleted, text, id }) => {
    return (
        <Link href={`#${id}`} underline='none'>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    cursor: 'pointer'
                }}
            >
                <Typography
                    component={'span'}
                    color='GrayText'
                >
                    {text}
                </Typography>
                {isCompleted ? <Done color='success' fontSize='small' /> : <Close color='error' fontSize='small' />}
            </Box>
        </Link>
    )
}

export default CompletedInfo
