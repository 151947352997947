const salesPrice = {
    name: 'salesPrice',
    label: 'Sales Price'
}
const downPayment = {
    name: 'downPayment',
    label: 'Down Payment'
}
const closingCosts = {
    name: 'closingCosts',
    label: 'Closing Costs'
}
const mortgageTerm = {
    name: 'mortgageTerm',
    label: 'Mortgage Term (Years)'
}
const interestRate = {
    name: 'interestRate',
    label: 'Interest Rate'
}
const monthlyPMI = {
    name: 'monthlyPMI',
    label: 'Monthly PMI'
}
const annualPropertyTaxes = {
    name: 'annualPropertyTaxes',
    label: 'Annual Property Taxes'
}
const annualPropertyInsurance = {
    name: 'annualPropertyInsurance',
    label: 'Annual Property Insurance'
}
const annualMaintenance = {
    name: 'annualMaintenance',
    label: 'Annual Maintenance'
}
const monthlyHOADues = {
    name: 'monthlyHOADues',
    label: 'Monthly HOA Dues'
}
const monthlyUtilities = {
    name: 'monthlyUtilities',
    label: 'Monthly Utilities'
}
const monthlyManagementFees = {
    name: 'monthlyManagementFees',
    label: 'Monthly Management Fees'
}
const vacancyRate = {
    name: 'vacancyRate',
    label: 'Vacancy Rate (Days Per Year)'
}
const monthlyRent = {
    name: 'monthlyRent',
    label: 'Monthly Rent'
}
const desiredCF = {
    name: 'desiredCF',
    label: 'Desired Cash Flow (Per Month)'
}
export const cashFlowFields = [
    salesPrice,
    downPayment,
    closingCosts,
    mortgageTerm,
    interestRate,
    monthlyPMI,
    annualPropertyTaxes,
    annualPropertyInsurance,
    annualMaintenance,
    monthlyHOADues,
    monthlyUtilities,
    monthlyManagementFees,
    vacancyRate,
    monthlyRent
]

export const breakevenFields = [...cashFlowFields.slice(1), desiredCF]

export const mortgageFields = [...cashFlowFields.slice(0, 6)]

export const ownershipFields = [...cashFlowFields.slice(0, 10)]
