import { FC } from 'react'

import SupportLinks from '../../components/SupportLinks'

const Support: FC = () => {
    return (
        <>
            <SupportLinks />
        </>
    )
}

export default Support
