/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { sendShareEmail } from './email'
import {
    addDoc,
    collection,
    arrayUnion,
    setDoc,
    doc,
    arrayRemove
} from 'firebase/firestore'
import { COLLECTION_NAMES } from '../constants/firestore'
import { db } from '../firebase'
import { NEW_PROPERTY } from '../constants/property'
import { convertNonNumericToZero } from './calculations'

export const addNewProperty =
    async (uid: string | undefined, isAnonymous: boolean | undefined, email: string | undefined | null): Promise<string> => {
        if (uid !== undefined && isAnonymous !== undefined) {
            const newProperty: any = refinePropertyValues(NEW_PROPERTY)
            const authDoc: any = {}
            authDoc.sharedWith = isAnonymous ? [] : [email]
            authDoc.uid = uid
            const docRef = await addDoc(collection(db, COLLECTION_NAMES.PROPERTIES_COLLECTION), { ...newProperty, ...authDoc })
            const id = docRef.id
            // addNewPropertyToAnalysis(uid, isAnonymous, email, id).then(() => { }).catch(() => { })
            return id
        } else {
            return ''
        }
    }

export const addNewPropertyToAnalysis =
    async (uid: string | undefined, isAnonymous: boolean | undefined, email: string | undefined | null, id: string): Promise<void> => {
        if (uid !== undefined && isAnonymous !== undefined) {
            const authDoc: any = {}
            authDoc.sharedWith = isAnonymous ? [] : [email]
            authDoc.uid = uid
            authDoc.address = NEW_PROPERTY.propertyInfo.streetAddress
            await setDoc(doc(db, COLLECTION_NAMES.PROPERTY_ANALYSIS_COLLECTION, id), authDoc)
        }
    }

export const shareProperties = async (
    email: string,
    subject: string,
    message: string,
    selectedProperties: string[]
): Promise<void> => {
    const promises = selectedProperties.map(async select => {
        return await setDoc(
            doc(db, COLLECTION_NAMES.PROPERTIES_COLLECTION, select),
            {
                sharedWith: arrayUnion(email)
            },
            { merge: true }
        )
    })
    await Promise.all(promises)
    await sendShareEmail(email, subject, message)
}

export const sharePropertyGroups = async (
    email: string,
    subject: string,
    message: string,
    rowIds: string[],
    groupId: string
): Promise<void> => {
    const promises = rowIds.map(async row => {
        return await setDoc(
            doc(db, COLLECTION_NAMES.PROPERTIES_COLLECTION, row),
            {
                sharedWith: arrayUnion(email)
            },
            { merge: true }
        )
    })
    promises.push(setDoc(
        doc(db, COLLECTION_NAMES.GROUPS_COLLECTION, groupId),
        {
            sharedWith: arrayUnion(email)
        },
        { merge: true }
    ))
    await Promise.all(promises)
    await sendShareEmail(email, subject, message)
}

export const removePropertiesFromGroup = async (
    selectedProperties: string[],
    groupId: string
): Promise<void> => {
    const promises = selectedProperties.map(async select => {
        return await setDoc(
            doc(db, COLLECTION_NAMES.PROPERTIES_COLLECTION, select),
            {
                groups: arrayRemove(groupId)
            },
            { merge: true }
        )
    })
    await Promise.all(promises)
}

export const refinePropertyValues = (property: any) => {
    const { cashFlowInfo, breakevenInfo, mortgageInfo, ownershipInfo } = property
    const refinedProperty = { ...property }
    refinedProperty.cashFlowInfo = {
        cashFlow: Number(cashFlowInfo.cashFlow) || 0,
        cashFlowFields: convertNonNumericToZero(cashFlowInfo.cashFlowFields)
    }
    refinedProperty.breakevenInfo = {
        breakeven: Number(breakevenInfo.breakeven) || 0,
        breakevenFields: convertNonNumericToZero(breakevenInfo.breakevenFields)
    }
    refinedProperty.mortgageInfo = {
        mortgage: Number(mortgageInfo.mortgage) || 0,
        mortgageFields: convertNonNumericToZero(mortgageInfo.mortgageFields)
    }
    refinedProperty.ownershipInfo = {
        ownership: Number(ownershipInfo.ownership) || 0,
        ownershipFields: convertNonNumericToZero(ownershipInfo.ownershipFields)
    }

    return refinedProperty
}
