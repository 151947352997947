import { literal, object, string, number, TypeOf, coerce } from 'zod'

export const MortgageSchema = object({
    salesPrice: coerce.number({ invalid_type_error: 'Sales Price is required' }).min(0, { message: 'SalesPrice must be > 0' })
        .max(999999999, { message: 'SalesPrice must be < 999999999' }),
    // .default(250000),
    downPayment: coerce.number({ invalid_type_error: 'Down Payment is required' }).min(0, { message: 'Down Payment must be > 0' })
        .max(999999999, { message: 'Down Payment must be < 999999999' }),
    // .default(50000),
    closingCosts: coerce.number({ invalid_type_error: 'Closing Costs is required' }).min(0, { message: 'Closing Costs must be > 0' })
        .max(999999999, { message: 'Closing Costs must be < 999999999' }),
    // .default(3000),
    mortgageTerm: coerce.number({ invalid_type_error: 'Mortgage Term (Years) is required' }).min(0, { message: 'Mortgage Term (Years) must be > 0' })
        .max(999999999, { message: 'Mortgage Term (Years) must be < 999999999' }),
    // .default(30),
    interestRate: coerce.number({ invalid_type_error: 'Interest Rate is required' }).min(0, { message: 'Interest Rate must be > 0' })
        .max(999999999, { message: 'Interest Rate must be < 999999999' }),
    // .default(4.50),
    monthlyPMI: coerce.number({ invalid_type_error: 'Monthly PMI is required' }).min(0, { message: 'Monthly PMI must be > 0' })
        .max(999999999, { message: 'Monthly PMI must be < 999999999' })
    // .default(100)
})

export type MortgageInput = TypeOf<typeof MortgageSchema>
