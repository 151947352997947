import { Box, Typography, Button } from '@mui/material'
import { FC, useEffect, useState, MouseEventHandler } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PropertyDetails from '../../../components/PropertyFormComponents/PropertyDetails'
import PropertyInfoForm from '../../../components/PropertyFormComponents/PropertyInfoForm'

import { useDocumentData } from 'react-firebase-hooks/firestore'
import { doc, setDoc } from 'firebase/firestore'
import { db, storage } from '../../../firebase'
import { COLLECTION_NAMES } from '../../../constants/firestore'
import { ROUTES } from '../../../constants/routes'
import CustomLoader from '../../../components/CustomLoader'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'

import { CashFlowInput, CashFlowSchema } from '../../../components/Calculators/CashFlow/schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import CashFlowForm from '../../../components/PropertyFormComponents/CashFlowForm/CashFlowForm'
import BreakevenForm from '../../../components/PropertyFormComponents/BreakevenForm/BreakevenForm'
import { BreakevenInput, BreakevenSchema } from '../../../components/Calculators/Breakeven/schema'
import { MortgageInput, MortgageSchema } from '../../../components/Calculators/Mortgage/schema'
import { OwnershipInput, OwnershipSchema } from '../../../components/Calculators/Ownersip/schema'
import MortageForm from '../../../components/PropertyFormComponents/MortageForm/MortageForm'
import OwnershipForm from '../../../components/PropertyFormComponents/Ownership/Ownership'
import { breakevenFields, cashFlowFields, mortgageFields, ownershipFields, propertyFormSteps, propertyInfo } from '../../../constants/property'
import { PropertyInfoInput, PropertyInfoSchema } from '../../../components/PropertyFormComponents/PropertyInfoForm/schema'
import { getBreakeven, getCashFlow, getMortage, getOwnership } from '../../../utils/calculations'
import { refinePropertyValues } from '../../../utils/property'
import RiskAssesmentForm from '../../../components/PropertyFormComponents/RiskAssesmentForm/RiskAssesmentForm'

const PropertyForm: FC = () => {
    const { id } = useParams()

    const navigate = useNavigate()

    // property details states
    const [propertyPhoto, setPropertyPhoto] = useState<File | null>(null)
    const [preview, setPreview] = useState<undefined | string>('')
    const [filename, setFilename] = useState<string>('')
    const [formSteps, setFormSteps] = useState(propertyFormSteps)

    // property information form states
    const propertyInfoMethods = useForm<PropertyInfoInput>({
        resolver: zodResolver(PropertyInfoSchema),
        defaultValues: propertyInfo
    })
    const isValidPropertyInfo = propertyInfoMethods.formState.isValid

    // Cash Flow form states
    const cashFlowMethods = useForm<CashFlowInput>({
        resolver: zodResolver(CashFlowSchema),
        defaultValues: cashFlowFields
    })
    const isValidCashFlow = cashFlowMethods.formState.isValid

    // Breakeven form states
    const breakevenMethods = useForm<BreakevenInput>({
        resolver: zodResolver(BreakevenSchema),
        defaultValues: breakevenFields
    })
    const isValidBreakeven = breakevenMethods.formState.isValid

    // Mortage form states
    const mortgageMethods = useForm<MortgageInput>({
        resolver: zodResolver(MortgageSchema),
        defaultValues: mortgageFields
    })
    const isValidMortage = mortgageMethods.formState.isValid

    // Ownership form states
    const ownershipMethods = useForm<OwnershipInput>({
        resolver: zodResolver(OwnershipSchema),
        defaultValues: ownershipFields
    })
    const isValidOwnership = ownershipMethods.formState.isValid

    const propertyRef = doc(db, COLLECTION_NAMES.PROPERTIES_COLLECTION, id as string)

    const [propertyData, propertyDataLoading, propertyDataError] = useDocumentData(
        propertyRef
        , {
            snapshotListenOptions: { includeMetadataChanges: true }
        }
    )

    const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (name in cashFlowFields) {
            cashFlowMethods.setValue(name as keyof CashFlowInput, +value)
        }
        if (name in breakevenFields) {
            breakevenMethods.setValue(name as keyof BreakevenInput, +value)
        }
        if (name in mortgageFields) {
            mortgageMethods.setValue(name as keyof MortgageInput, +value)
        }
        if (name in ownershipFields) {
            ownershipMethods.setValue(name as keyof OwnershipInput, +value)
        }
        console.log('Name : ', name)
        console.log('Value : ', value)
    }

    useEffect(() => {
        if (propertyDataLoading) return
        if (propertyDataError !== undefined || propertyData === undefined) navigate(ROUTES.OVERVIEW)
        if (propertyData != null) {
            const { propertyPhoto, propertyInfo, cashFlowInfo, mortgageInfo, breakevenInfo, ownershipInfo } = propertyData
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (propertyPhoto) {
                const propertyPhotoRef = ref(storage, propertyPhoto)
                getDownloadURL(propertyPhotoRef).then((url: string) => setPreview(url)).catch(() => { })
            }
            propertyInfoMethods.reset(propertyInfo)
            cashFlowMethods.reset(cashFlowInfo.cashFlowFields)
            breakevenMethods.reset(breakevenInfo.breakevenFields)
            mortgageMethods.reset(mortgageInfo.mortgageFields)
            ownershipMethods.reset(ownershipInfo.ownershipFields)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertyData, propertyDataError, propertyDataLoading, navigate])

    const saveBtnClickHandler: MouseEventHandler<HTMLButtonElement> = (e): void => {
        const propertyInfo = propertyInfoMethods.getValues()
        const cashFlowFields = cashFlowMethods.getValues()
        const cashFlow = getCashFlow(cashFlowFields)
        const breakevenFields = breakevenMethods.getValues()
        const breakeven = getBreakeven(breakevenFields)
        const mortgageFields = mortgageMethods.getValues()
        const mortgage = getMortage(mortgageFields)
        const ownershipFields = ownershipMethods.getValues()
        const ownership = getOwnership(ownershipFields)
        const propertyDoc = {
            propertyPhoto: '',
            propertyInfo,
            cashFlowInfo: {
                cashFlow,
                cashFlowFields
            },
            breakevenInfo: {
                breakeven,
                breakevenFields
            },
            mortgageInfo: {
                mortgage,
                mortgageFields
            },
            ownershipInfo: {
                ownership,
                ownershipFields
            }
        }
        if (propertyPhoto !== null) {
            const splitted = filename.split('.')
            const extension = splitted[splitted.length - 1]
            const uploadFilename = `properties/${propertyRef.id}/main.${extension}`
            propertyDoc.propertyPhoto = uploadFilename
            const uploadPhotoRef = ref(storage, uploadFilename)
            uploadBytes(uploadPhotoRef, propertyPhoto).then(() => { console.log('uploaded successfully') }).catch(() => { })
        }
        const refinedProperty = refinePropertyValues(propertyDoc)
        setDoc(propertyRef, refinedProperty, { merge: true })
            .then(() => { navigate(ROUTES.PROPERTIES) })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        if (propertyPhoto === null) {
            setPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(propertyPhoto)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [propertyPhoto])

    useEffect(() => {
        const steps = { ...propertyFormSteps }
        steps.propertyInfo.isCompleted = isValidPropertyInfo
        steps.cashFlowInfo.isCompleted = isValidCashFlow
        steps.breakevenInfo.isCompleted = isValidBreakeven
        steps.mortgageInfo.isCompleted = isValidMortage
        steps.ownershipInfo.isCompleted = isValidOwnership
        setFormSteps(steps)
    }, [isValidPropertyInfo, isValidCashFlow, isValidMortage, isValidBreakeven, isValidOwnership])

    return (
        <>
            {
                propertyDataLoading && <CustomLoader />
            }
            {
                !propertyDataLoading && propertyDataError === undefined && <Box sx={{ display: 'flex', flexDirection: 'column', overflowX: 'auto' }}>
                    <Typography
                        variant="h2"
                        sx={{
                            fontWeight: '600',
                            fontSize: { lg: '24px', md: '20px', xs: '18px' },
                            lineHeight: '36px',
                            letterSpacing: '0.1px',
                            marginBottom: { lg: '30px', xs: '15px' }
                        }}
                    >
                        Property form
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '20px'
                        }}
                    >
                        <PropertyDetails
                            setFile={setPropertyPhoto}
                            preview={preview}
                            filename={filename}
                            setFilename={setFilename}
                            formSteps={formSteps}
                        />
                        <Box sx={{ backgroundColor: 'white', padding: '20px', display: 'flex', flexDirection: 'column', gap: '50px' }}>
                            <PropertyInfoForm methods={propertyInfoMethods} />
                            <CashFlowForm onChange={handleInputChange} methods={cashFlowMethods} />
                            <BreakevenForm onChange={handleInputChange} methods={breakevenMethods} />
                            <MortageForm onChange={handleInputChange} methods={mortgageMethods} />
                            <OwnershipForm onChange={handleInputChange} methods={ownershipMethods} />
                            <RiskAssesmentForm />
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size='medium'
                                    onClick={saveBtnClickHandler}
                                >
                                    Save Info
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            }

        </>
    )
}

export default PropertyForm
