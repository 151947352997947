import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { ROUTES } from '../constants/routes'
import { toast } from 'react-toastify'

const PrivateRoutes = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
    const location = useLocation()
    if (!isAuthenticated) {
        toast('You need to be logged in first!', {
            type: 'info'
        })
    }
    return isAuthenticated ? <Outlet /> : <Navigate to={ROUTES.LOGIN} state={{ redirectTo: location.pathname }} />
}

export default PrivateRoutes
