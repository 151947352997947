import { Box, Typography } from '@mui/material'
import { FC } from 'react'

const RiskAssesmentForm: FC<{}> = () => {
    return (
        <Box
            id="riskAssessmentInfo"
        >
            <Typography
                variant="h3"
                sx={{
                    fontWeight: '600',
                    fontSize: { lg: '24px', md: '20px', xs: '18px' },
                    marginTop: '10px'
                }}
            >
                Risk Assesment
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '40px',
                    gap: '30px'
                }}
            >
                <Typography>Coming soon...</Typography>
            </Box>
        </Box >
    )
}

export default RiskAssesmentForm
