import { serverTimestamp } from 'firebase/firestore'

export const COLLECTION_NAMES = {
    PROPERTIES_COLLECTION: 'Properties',
    PROPERTY_ANALYSIS_COLLECTION: 'PropertyAnalysis',
    USERS_COLLECTION: 'users',
    MAIL_COLLECTION: 'mail',
    NOTIFICATIONS_COLLECTION: 'Notifications',
    GROUPS_COLLECTION: 'PropertyGroups',
    FEEDBACKS_COLLECTION: 'Feedbacks',
    SURVEYS_COLLECTION: 'Surveys',
    SERVICES: 'services',
    CONTACTS: 'contacts',
    BLOGS: 'blogs'
}
