// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import {
    // connectAuthEmulator,
    getAuth
} from 'firebase/auth'
import {
    // connectFirestoreEmulator,
    getFirestore
} from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import {
    // connectFunctionsEmulator,
    getFunctions
} from 'firebase/functions'
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)
const storage = getStorage(app)
const functions = getFunctions(app)

// connect emulators for local testing
// connectAuthEmulator(auth, "http://localhost:9099");
// connectFunctionsEmulator(functions, "localhost", 5001);
// connectFirestoreEmulator(db, 'localhost', 8080);

export { storage, auth, db, functions, app as default }
