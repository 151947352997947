import { Box, Typography } from '@mui/material'
import { CSSProperties, FC, useState, useEffect, MouseEvent } from 'react'
import Logo from '../../assets/Navbars/transparent-app-logo.png'
import MenuIcon from '@mui/icons-material/Menu'
import { User } from 'firebase/auth'
import { NavigateFunction, Link } from 'react-router-dom'
import HeaderLinks from './HeaderLinks'
import OverviewBcgMob from '../../assets/Overview/OverviewBcgMob.png'

import { ROUTES } from '../../constants/routes'
import SigninUserNavBarMenu from './SigninUserNavBarMenu'
import { ArrowDropDown } from '@mui/icons-material/'

interface MobileHeaderProps {
    signedInUserData: User | null | undefined
    Loading: boolean
    userLoading: boolean
    user: User | null | undefined
    navigate: NavigateFunction
    handleAvatarClick: (event: MouseEvent<HTMLElement>) => void
    anchorEl: null | HTMLElement
    handleClose: () => void
    logoutUser: () => void
    open: boolean
    customStyles?: CSSProperties
}

const MobileHeader: FC<MobileHeaderProps> = ({
    signedInUserData,
    Loading,
    userLoading,
    user,
    navigate,
    handleAvatarClick,
    anchorEl,
    open,
    handleClose,
    logoutUser,
    customStyles = {}
}) => {
    const [isOpenSideBar, setIsOpenMobileHeader] = useState(false)

    useEffect(() => {
        setIsOpenMobileHeader(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname])

    return (
        <>
            <Box>
                {/* SIDEBAR BACKDROP */}
                <Box
                    component="div"
                    sx={{
                        position: 'fixed',
                        inset: 0,
                        backgroundColor: '#00000045',
                        zIndex: 21,
                        transform: `translateX(${isOpenSideBar ? '0%' : '-100%'})`
                    }}
                    onClick={() => setIsOpenMobileHeader(false)}
                />
                {/* SIDEBAR */}
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        backgroundColor: 'white',
                        width: '90vw',
                        maxWidth: '320px',
                        zIndex: 22,
                        transition: 'all .5s',
                        transform: `translateX(${isOpenSideBar ? '0%' : '-100%'})`
                    }}
                >
                    <Box
                        component="div"
                        sx={{
                            display: 'flex',
                            padding: '30px',
                            flexDirection: 'column'
                        }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <HeaderLinks isMobileHeader user={user} userLoading={userLoading} />
                        <HeaderLinks isMobileHeader isLoginSignupLinks user={user} userLoading={userLoading} />
                    </Box>
                </Box>
                <Box
                    sx={{
                        position: 'fixed',
                        left: 0,
                        top: 0,
                        right: 0,
                        padding: '10px',
                        display: 'flex',
                        zIndex: 20,
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundImage: `url(${OverviewBcgMob})`,
                        minHeight: '60px',
                        ...customStyles
                    }}
                >
                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        <Link to={user === null ? ROUTES.LOGIN : ROUTES.OVERVIEW}>
                            <img src={Logo} width={30} height={30} />
                        </Link>
                    </Box>
                    <Box component="span" onClick={() => setIsOpenMobileHeader(true)}>
                        <MenuIcon sx={{ color: 'white', width: '35px', height: '35px' }} />
                    </Box>
                    {(user != null)
                        ? (
                            <Box sx={{ display: 'flex', gap: '10px', maxWidth: '40%' }}>
                                {(signedInUserData != null) && !Loading
                                    ? (
                                        <>
                                            <Box component="div" sx={{ display: 'flex', overflow: 'hidden' }} onClick={user?.isAnonymous ? () => {} : handleAvatarClick}>
                                                <Typography sx={{ color: 'white', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                                    {user?.isAnonymous ? '' : 'Hi '}
                                                    {signedInUserData?.displayName}
                                                </Typography>
                                                <Typography>{user?.isAnonymous ? <></> : <ArrowDropDown fontSize="small" sx={{ marginBottom: '-5px', color: 'white' }} />}</Typography>
                                            </Box>
                                            {Boolean(signedInUserData) && !Loading
                                                ? (
                                                    <SigninUserNavBarMenu
                                                        anchorEl={anchorEl}
                                                        open={open}
                                                        handleClose={handleClose}
                                                        logoutUser={logoutUser}
                                                        signedInUserData={signedInUserData}
                                                        user={user}
                                                        navigate={navigate}
                                                    />
                                                )
                                                : null}
                                        </>
                                    )
                                    : null}
                            </Box>
                        )
                        : null}
                </Box>
            </Box>
        </>
    )
}

export default MobileHeader
