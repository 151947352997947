import { ROUTES } from './routes'

export const ACCEPTED_FILE_TYPES = [
    'image/jpeg',
    'image/png',
    'image/jpg'
]

export const SERVICE_PAGE_BANNER_URL = 'https://firebasestorage.googleapis.com/v0/b/gambitresume-3.appspot.com/o/Assets%2FServices%2FServices%20Banner.jpg?alt=media&token=84fea233-dda8-4497-8645-8db32c2d2957'

export const BLOGS_PAGE_BANNER_URL = 'https://firebasestorage.googleapis.com/v0/b/gambitresume-3.appspot.com/o/Assets%2FBlogs%2Fblogs-banner.jpg?alt=media&token=413606c2-7fb8-41f0-bffb-ab0e07c23f2f'

export const BLOGS_SLIDER_CONFIG = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 }
}

export const COLOR_TO_SEVERITIES = {
    red: 'error',
    blue: 'info',
    green: 'success',
    yellow: 'warning'
}

export const MEMBERSHIPS = [
    { id: 1, name: 'free', price: 0 },
    { id: 2, name: 'normal', price: 29 },
    { id: 3, name: 'premium', price: 59 }
]

export const LOGIN_SIGNUP_ROUTES = [ROUTES.LOGIN, ROUTES.SIGNUP]
