import { useRef, useState, FC, Dispatch, SetStateAction } from 'react'
import {
    Button,
    Typography,
    Link,
    Box
} from '@mui/material'
import { ACCEPTED_FILE_TYPES } from '../../../constants'

interface FileFieldProps {
    name: string
    filename: string
    setFilename: Dispatch<SetStateAction<string>>
    preview: undefined | string
    setFile: (value: File | null) => void
}

const CustomFileField: FC<FileFieldProps> = ({
    name,
    preview,
    filename,
    setFile,
    setFilename
}) => {
    const [error, setError] = useState('')
    const ref = useRef(null)

    const handleChange = (e: any): void => {
        const tempFile: File = e.target.files[0]

        if ((ACCEPTED_FILE_TYPES.includes(tempFile.type))) {
            setError('')
            setFile(tempFile)
            setFilename(tempFile.name)
        } else {
            setFilename('')
            setFile(null)
            setError('Can only upload jpeg, jpg, or png files!')
        }
    }

    const handleRemove = (): void => {
        setFilename('')
        setFile(null)
    }

    return (
        <>
            {name !== '' && (
                <Typography
                    component="p"
                    sx={{
                        color: '#171725',
                        fontWeight: { sm: '600', xs: '700' },
                        fontSize: { sm: '18px', xs: '14px' },
                        lineHeight: { sm: '27px', xs: '18px' },
                        marginBottom: { sm: '16px', xs: '10px' }
                    }}
                >
                    {name}
                </Typography>
            )}
            <Box
                sx={{
                    display: 'flex'
                }}
            >
                <Box>
                    <Button
                        variant="contained"
                        component="label"
                        color="info"
                        size='small'
                    >
                        {preview === undefined ? 'Add Photo' : 'Edit Photo'}
                        <input
                            type="file"
                            hidden
                            ref={ref}
                            onChange={(e) => {
                                handleChange(e)
                            }}
                            accept={ACCEPTED_FILE_TYPES.join(',')}
                        />
                    </Button>
                    {filename !== '' && (
                        <>
                            <Typography
                                component="span"
                                sx={{
                                    color: '#171725',
                                    fontWeight: { sm: '400', xs: '500' },
                                    fontSize: { sm: '16px', xs: '12px' },
                                    lineHeight: { sm: '27px', xs: '18px' },
                                    marginBottom: { sm: '16px', xs: '10px' },
                                    marginLeft: { sm: '16px', xs: '10px' },
                                    wordWrap: 'break-word'
                                }}
                            >
                                {filename}
                            </Typography>
                            <Link
                                sx={{
                                    textDecoration: 'none',
                                    marginLeft: { sm: '5px', xs: '2px' },
                                    fontSize: { sm: '12px', xs: '9px' },
                                    ':hover': {
                                        cursor: 'pointer'
                                    }
                                }}
                                onClick={handleRemove}
                            >
                                &#10060;
                            </Link>
                        </>
                    )}
                    {error !== '' && (
                        <>
                            <Typography
                                component="span"
                                sx={{
                                    color: 'orange',
                                    fontWeight: { sm: '400', xs: '500' },
                                    fontSize: { sm: '16px', xs: '12px' },
                                    lineHeight: { sm: '27px', xs: '18px' },
                                    marginBottom: { sm: '16px', xs: '10px' },
                                    marginLeft: { sm: '16px', xs: '10px' }
                                }}
                            >
                                {error}
                            </Typography>
                        </>
                    )}

                </Box>
            </Box>
        </>
    )
}

export default CustomFileField
