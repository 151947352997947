import { Box, Divider, useMediaQuery } from '@mui/material'
import { DocumentData, DocumentReference } from 'firebase/firestore'
import { Dispatch, FC, Fragment, SetStateAction } from 'react'
import theme from '../../../theme'
import CustomFileField from '../../CustomFields/CustomFileField'
import CompletedInfo from './CompletedInfo'
import { PropertyFormSteps } from '../../../constants/property'

interface Props {
    setFile: any
    preview: string | undefined
    formSteps: PropertyFormSteps
    filename: string
    setFilename: Dispatch<SetStateAction<string>>
}

const PropertyDetails: FC<Props> = ({ setFile, formSteps, preview, filename, setFilename }) => {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <>
            <Box
                sx={{
                    padding: '14px',
                    backgroundColor: 'white',
                    height: '550px',
                    position: !isMobile ? 'sticky' : 'initial',
                    top: '20px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#e1e1e1',
                        width: '250px',
                        height: '180px',
                        borderStyle: 'dashed',
                        borderColor: 'silver'
                    }}
                >
                    {preview !== undefined && <Box
                        component="img"
                        sx={{
                            width: '100%',
                            height: '100%'
                        }}
                        src={preview}
                    />}
                    {preview === undefined && <CustomFileField filename={filename} setFilename={setFilename} preview={preview} setFile={setFile} name="" />}
                </Box>
                {preview !== undefined && <CustomFileField filename={filename} setFilename={setFilename} preview={preview} setFile={setFile} name="" />}
                <Box sx={{ paddingX: '8px', marginTop: '24px' }}>
                    {Object.keys(formSteps).map((stepKey) => {
                        const step = formSteps[stepKey as keyof PropertyFormSteps]
                        return <Fragment key={step.text}>
                            <CompletedInfo id={stepKey} text={step.text} isCompleted={step.isCompleted} />
                            <Divider sx={{ marginBottom: '18px' }} />
                        </Fragment>
                    })}
                </Box>
            </Box>
        </>
    )
}

export default PropertyDetails
