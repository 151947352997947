import {
    Box,
    Typography,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Button,
    Link,
    Card
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../constants/routes'
import { auth } from '../../../firebase'
import CustomInput from '../../CustomInput'
import nextImage from '../../../assets/Login/arrow_right.png'
import {
    signInWithEmailAndPassword
} from 'firebase/auth'
import { FC, SyntheticEvent, useEffect, useState, useContext } from 'react'

import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { signInAnonymous } from '../../../utils/authentication'
import { ErrnoException } from '../../../types/error'

import { AnonymousJobsCTX } from '../../../contexts/anonymousJobs'

interface AuthModalProps {
    callback: string | null
    isMobile: boolean
    setOpenModal?: (isOpen: boolean) => void
}

const AuthModal: FC<AuthModalProps> = ({
    callback,
    isMobile,
    setOpenModal
}) => {
    const navigate = useNavigate()

    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')

    const [rememberPassWord, setRememberPassWord] = useState(false)
    // const [feedback, setFeedback] = useState<string>("");
    // const [feedback, setFeedback] = useState<string>("");

    const navigator = (): void => {
        if (callback !== null) navigate(callback)
        else navigate('/')
    }

    const { setHasAnonymousJobs, setAnonymousJobs } =
        useContext(AnonymousJobsCTX)

    const handleAnonymousSignIn = (): void => {
        signInAnonymous(navigator, setHasAnonymousJobs, setAnonymousJobs)
    }

    const handleLoginWithEmail = (e: SyntheticEvent): void => {
        e.preventDefault()

        // if (password.length < 6) {
        //   alert("Password must be at least 6 characters long");
        //   return;
        // }

        if (email === '' || password === '') {
            // alert("Please fill all the fields");

        } else {
            signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                })
                .catch((err: ErrnoException) => {
                    const errorCode = err.code
                    const errorMessage = err.message
                    console.log('error is: ', errorCode, errorMessage)
                    alert(`Error logging In : ${errorMessage}`)
                    // ..
                })
        }
    }

    useEffect(() => {
        console.log('master')
    }, [])

    return (
        <>
            {!isMobile
                ? (
                    <>
                        <Box
                            component={'div'}
                            sx={{
                                width: { md: '560px', sm: '450px', xs: '90%' },
                                margin: '0 auto',
                                // border: "1px solid #000000",
                                background: '#FFFFFF',
                                boxShadow: 1,
                                borderRadius: '20px',
                                padding: { sm: '30px', xs: '15px' }
                            }}
                        >
                            <Box component="div">
                                <Box component="div">
                                    <Typography
                                        component="div"
                                        sx={{
                                            width: '100%',
                                            textAlign: 'center',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Typography
                                            variant="h5"
                                            component="div"
                                            sx={{
                                                fontFamily: "'Poppins'",
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                fontSize: { md: '24px', sm: '20px', xs: '16px' },
                                                lineHeight: '36px',
                                                letterSpacing: '0.15px',
                                                color: '#171725'
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: '#0062FF',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                    fontSize: {
                                                        md: '24px',
                                                        sm: '20px',
                                                        xs: '18px'
                                                    },
                                                    lineHeight: '36px',
                                                    letterSpacing: '0.15px'
                                                }}
                                                component={'span'}
                                            >
                                                Login {' '}
                                            </Typography>
                                            | Continue as guest user
                                        </Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Box
                                                onClick={() => handleAnonymousSignIn()}
                                                component={'img'}
                                                sx={{
                                                    display: 'block',
                                                    marginLeft: '14px',
                                                    width: { sm: '36px', xs: '24px' },
                                                    height: { sm: '36px', xs: '24px' },
                                                    cursor: 'pointer'
                                                }}
                                                src={nextImage}
                                                title={'next'}
                                                alt="next"
                                            />
                                        </Box>
                                    </Typography>

                                    <Typography
                                        component="div"
                                        variant="h5"
                                        sx={{
                                            fontFamily: "'Poppins'",
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: { sm: '18px', xs: '15px' },
                                            lineHeight: '27px',
                                            letterSpacing: '0.02em',
                                            color: '#94959B',
                                            textAlign: 'center',
                                            marginTop: '11px'
                                        }}
                                    >
                                        Don’t have an account? {' '}
                                        <Link
                                            onClick={() =>
                                                navigate(
                                                    ROUTES.SIGNUP +
                                                    (callback !== null
                                                        ? '?callback=' + encodeURIComponent(callback)
                                                        : '')
                                                )
                                            }
                                            sx={{
                                                fontFamily: "'Poppins'",
                                                fontStyle: 'normal',
                                                fontWeight: '500',
                                                cursor: 'pointer',
                                                fontSize: { sm: '18px', xs: '15px' },
                                                lineHeight: '27px',
                                                letterSpacing: '0.02em',
                                                color: '#3865F3'
                                            }}
                                            component={'span'}
                                        >
                                            Register
                                        </Link>
                                    </Typography>

                                    {/* ####################################################################### */}
                                    {/* ________________________ Complete Login Form ________________________ */}
                                    {/* ####################################################################### */}

                                    <form onSubmit={handleLoginWithEmail}>
                                        {/* Input Box */}
                                        <CustomInput
                                            placeHolder={'Your Email'}
                                            type="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required={true}
                                            Icon={<EmailOutlinedIcon />}
                                            InputStyles={{
                                                background: '#F6F6F6',
                                                borderRadius: '12px',
                                                marginTop: { sm: '45px', xs: '25px' }
                                            }}
                                        />
                                        <CustomInput
                                            placeHolder={'Password'}
                                            type="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required={true}
                                            Icon={<LockOutlinedIcon />}
                                            InputStyles={{
                                                background: '#F6F6F6',
                                                borderRadius: '12px',
                                                marginTop: '16px'
                                            }}
                                        />
                                        {/* Input Box */}
                                        {/* Forgot Password Section */}
                                        <Box component={'div'}>
                                            <FormGroup
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    marginTop: '42px'
                                                }}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onChange={() =>
                                                                setRememberPassWord(!rememberPassWord)
                                                            }
                                                            checked={rememberPassWord}
                                                            size="medium"
                                                        />
                                                    }
                                                    label={
                                                        <Box
                                                            sx={{
                                                                fontStyle: 'normal',
                                                                fontWeight: '600',
                                                                fontSize: { sm: '18px', xs: '14px' },
                                                                lineHeight: '27px',
                                                                letterSpacing: '0.02em',
                                                                color: '#222222'
                                                            }}
                                                        >
                                                            Remember me
                                                        </Box>
                                                    }
                                                />
                                                <Link
                                                    // onClick={
                                                    //   () => alert("Forgot Password")
                                                    // }

                                                    sx={{
                                                        lineHeight: '50px',
                                                        cursor: 'pointer',
                                                        fontStyle: 'normal',
                                                        fontWeight: '600',
                                                        fontSize: { sm: '18px', xs: '14px' },
                                                        letterSpacing: '0.02em',
                                                        color: '#3865F3'
                                                    }}
                                                    underline="hover"
                                                >
                                                    Forgot Password?
                                                </Link>
                                            </FormGroup>
                                        </Box>
                                        {/* Forgot Password Section */}
                                        {/* Login Button */}
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            sx={{
                                                height: { sm: '48px', xs: '35px' },
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                fontSize: { sm: '18px', xs: '14px' },
                                                lineHeight: '27px',
                                                letterSpacing: '0.02em',
                                                color: '#FFFFFF',
                                                background: '#3865F3',
                                                borderRadius: '12px',
                                                width: '100%',
                                                textTransform: 'none',
                                                mt: '13px'
                                            }}
                                        >
                                            Log In
                                        </Button>
                                        {/* Login Button */}
                                    </form>
                                    {/* social icons section */}
                                    {/* <SocialAuth userType={userType} callback={callback} /> */}
                                    {/* social icons section */}

                                    {/* ####################################################################### */}
                                    {/* ________________________ Complete Login Form ________________________ */}
                                    {/* ####################################################################### */}
                                </Box>
                            </Box>
                        </Box>
                    </>
                )
                : (
                    <>
                        <Box sx={{ paddingX: '16px' }}>
                            <Card
                                sx={{
                                    padding: '24px 24px 20px 24px',
                                    borderRadius: '16px',
                                    marginBottom: '10px'
                                }}
                            >
                                <Typography
                                    variant="h2"
                                    sx={{
                                        color: '#121212',
                                        fontWeight: '700',
                                        fontSize: '24px',
                                        lineHeight: '32px',
                                        textAlign: 'center',
                                        marginBottom: '24px'
                                    }}
                                >
                                    Easy steps to organize your project
                                </Typography>
                                <Button
                                    onClick={() => handleAnonymousSignIn()}
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                        backgroundColor: '#171725',
                                        fontWeight: '600',
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        paddingY: '16px',
                                        marginBottom: '16px',
                                        borderRadius: '16px',
                                        textTransform: 'none',
                                        '&:hover': {
                                            backgroundColor: '#3e3b3b'
                                        }
                                    }}
                                >
                                    Continue as guest User
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() => navigate('/signup')}
                                    fullWidth
                                    sx={{
                                        backgroundColor: '#3865F3',
                                        fontWeight: '600',
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        paddingY: '16px',
                                        marginBottom: '16px',
                                        borderRadius: '16px',
                                        textTransform: 'none',
                                        '&:hover': {
                                            backgroundColor: '#294ec4'
                                        }
                                    }}
                                >
                                    Sign Up
                                </Button>

                                <Button
                                    variant="text"
                                    fullWidth
                                    onClick={() => setOpenModal !== undefined ? setOpenModal(true) : null}
                                    sx={{
                                        fontWeight: '600',
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        paddingY: '16px',
                                        marginBottom: '16px',
                                        borderRadius: '16px',
                                        border: 'none',
                                        backgroundColor: '#F5F6FF',
                                        textTransform: 'none'
                                    }}
                                >
                                    Login
                                </Button>
                            </Card>
                        </Box>
                    </>
                )}
        </>
    )
}

export default AuthModal
