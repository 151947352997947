/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { FC, Fragment } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Box, Button, SvgIconTypeMap } from '@mui/material'
import { signOut, User } from 'firebase/auth'
import { auth } from '../../firebase'
import { ROUTES } from '../../constants/routes'
import { LOGIN_SIGNUP_ROUTES } from '../../constants'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { HolidayVillage, Insights, PhoneInTalk, Calculate } from '@mui/icons-material'

interface HeaderLinksProps {
    isMobileHeader?: boolean
    isLoginSignupLinks?: boolean
    user: User | null | undefined
    userLoading: boolean
}

interface NavBarLink {
    text: string
    isLink: boolean
    Icon?: OverridableComponent<SvgIconTypeMap>
    to?: string
    onClick?: () => void
}

export const HeaderLinks: FC<HeaderLinksProps> = ({ isMobileHeader = false, isLoginSignupLinks = false, user, userLoading }) => {
    const navigate = useNavigate()
    const loginBtnHandler = async () => {
        if (user !== null) await signOut(auth)
        navigate(ROUTES.LOGIN)
    }

    const signUpBtnHandler = async () => {
        if (user !== null) await signOut(auth)
        navigate(ROUTES.SIGNUP)
    }

    const authenticatedLinks: NavBarLink[] = [
        { Icon: Calculate, isLink: true, text: 'Calculators', to: ROUTES.CALCULATORS },
        { Icon: HolidayVillage, isLink: true, text: 'Properties', to: ROUTES.PROPERTIES },
        { Icon: Insights, isLink: true, text: 'Analysis', to: ROUTES.PROPERTY_ANALYSIS },
        { Icon: PhoneInTalk, isLink: true, text: 'Support', to: ROUTES.SUPPORT }
    ]
    const unAuthenticatedLinks: NavBarLink[] = []
    const publicLinks: NavBarLink[] = [
        { text: 'Services', isLink: true, to: ROUTES.SERVICES },
        { text: 'Blogs', isLink: true, to: ROUTES.BLOGS }
    ]
    const Links: NavBarLink[] = [...publicLinks, ...((user != null) && isMobileHeader ? authenticatedLinks : []), ...((user != null) ? [] : unAuthenticatedLinks)]

    return isLoginSignupLinks
        ? (
            <>
                {!userLoading && (user == null || user.isAnonymous) && !LOGIN_SIGNUP_ROUTES.includes(window.location?.pathname)
                    ? (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: isMobileHeader ? '15px' : '5px',
                                marginY: isMobileHeader ? '10px' : 0,
                                width: isMobileHeader ? '100%' : 'fit-content',
                                button: {
                                    color: 'black',
                                    borderRadius: '50px',
                                    border: '1px solid black',
                                    padding: '5px 15px',
                                    flexGrow: 1
                                }
                            }}
                        >
                            <Button onClick={loginBtnHandler}>Log In</Button>
                            <Button onClick={signUpBtnHandler}>Sign Up</Button>
                        </Box>
                    )
                    : null}
            </>
        )
        : (
            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    gap: { xs: '5px', md: '20px' },
                    justifyContent: { xs: 'center', sm: 'flex-end' },
                    alignItems: isMobileHeader ? 'flex-start' : 'center',
                    flexDirection: isMobileHeader ? 'column' : 'row',
                    'a, button': {
                        color: '#171725',
                        textDecoration: 'none',
                        svg: { color: 'grey' },
                        '&.active': { svg: { color: '#0062FF' }, button: { color: '#0062FF' } },
                        '&:hover': { backgroundColor: 'transparent' }
                    }
                }}
            >
                {Links.map(({ text, isLink, Icon, to, onClick }) => {
                    return (
                        <Fragment key={`${to ?? ''}-${text}`}>
                            {isLink && to
                                ? (
                                    <NavLink
                                        className={({ isActive }) => ((to === ROUTES.CALCULATIONS && location.pathname === ROUTES.OVERVIEW) || isActive ? 'active' : '')}
                                        key={to}
                                        to={to}
                                        style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
                                    >
                                        {(Icon != null) ? <Icon sx={{ width: '20px', height: '20px' }} /> : null}
                                        <Button sx={{ minWidth: 'unset' }}>{text}</Button>
                                    </NavLink>
                                )
                                : null}
                            {!isLink && (onClick != null) ? <Button onClick={onClick}>{text}</Button> : null}
                        </Fragment>
                    )
                })}
            </Box>
        )
}

export default HeaderLinks
