import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import CashFlowFields from '../../Calculators/CashFlow/CashFlowFields'
import { UseFormReturn } from 'react-hook-form'
import { CashFlowInput } from '../../Calculators/CashFlow/schema'

interface Props {
    methods: UseFormReturn<CashFlowInput>
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const CashFlowForm: FC<Props> = ({ methods, ...restProps }) => {
    return (
        <Box
            id="cashflowInfo"
        >
            <Typography
                variant="h3"
                sx={{
                    fontWeight: '600',
                    fontSize: { lg: '24px', md: '20px', xs: '18px' },
                    marginTop: '10px'
                }}
            >
                Cash Flow
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '40px',
                    gap: '30px'
                }}
            >
                <CashFlowFields methods={methods} {...restProps} />
            </Box>
        </Box >
    )
}

export default CashFlowForm
