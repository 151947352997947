import { Box, Tab, Tabs, Typography } from '@mui/material'
import { FC, useState } from 'react'
import CashFlow from './CashFlow'
import Breakeven from './Breakeven'
import Mortgage from './Mortgage'
import Ownership from './Ownersip'

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

const CustomTabPanel: FC<any> = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

const a11yProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    }
}

const Calculators: FC<any> = () => {
    const [value, setValue] = useState(0)

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'left' }}>
                <Typography
                    variant="h2"
                    sx={{
                        fontWeight: '600',
                        fontSize: { lg: '24px', md: '20px', xs: '18px' },
                        lineHeight: '36px',
                        letterSpacing: '0.1px',
                        marginBottom: { lg: '30px', xs: '15px' }
                    }}
                >
                        Calculators
                </Typography>
            </Box>
            <Box sx={{ width: '90%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'center' }}>
                    <Tabs centered value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="CashFlow" {...a11yProps(0)} />
                        <Tab label="Breakeven" {...a11yProps(1)} />
                        <Tab label="Mortgage" {...a11yProps(2)} />
                        <Tab label="Ownership" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <CashFlow />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <Breakeven />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <Mortgage />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                    <Ownership />
                </CustomTabPanel>
            </Box>
        </>
    )
}

export default Calculators
