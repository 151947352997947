/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { FC, useState, MouseEvent, ChangeEvent, Dispatch, SetStateAction, useEffect } from 'react'
import { alpha } from '@mui/material/styles'
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    Paper,
    Checkbox,
    Button,
    Grid
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { Data } from '../types'

import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import AddGroupModal from './AddGroupModal'
import SharePropertyModal from './SharePropertyModal'
import ShareGroupModal from './ShareGroupModal'
import { PlaylistRemove } from '@mui/icons-material'
import { removePropertiesFromGroup } from '../../../utils/property'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, db } from '../../../firebase'
import { getValue } from '../../../utils/input'
import DeleteIcon from '@mui/icons-material/Delete'
import { COLLECTION_NAMES } from '../../../constants/firestore'
import { doc, updateDoc } from 'firebase/firestore'

type Order = 'asc' | 'desc'

const stableSort = (array: readonly Data[], order: Order, orderBy: keyof Data): Data[] => {
    const stabilizedThis = [...array]
    stabilizedThis.sort((a: any, b: any) => {
        if (orderBy === 'address') {
            return order === 'asc' ? a[orderBy]?.localeCompare(b[orderBy]) : b[orderBy]?.localeCompare(a[orderBy])
        }
        return order === 'asc' ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy]
    })
    return stabilizedThis
}

interface HeadCell {
    id: keyof Data
    label: string
}

const headCells: readonly HeadCell[] = [
    {
        id: 'address',
        label: 'Address'
    },
    {
        id: 'cashFlow',
        label: 'Cash Flow'
    },
    {
        id: 'breakeven',
        label: 'Breakeven'
    },
    {
        id: 'mortgage',
        label: 'Mortgage'
    },
    {
        id: 'ownership',
        label: 'Ownership'
    }
]

interface EnhancedTableProps {
    numSelected: number
    onRequestSort: (event: MouseEvent<unknown>, property: keyof Data) => void
    onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void
    order: Order
    orderBy: string
    rowCount: number
}

const EnhancedTableHead: FC<EnhancedTableProps> = ({
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
}) => {
    const createSortHandler =
        (property: keyof Data) => (event: MouseEvent<unknown>) => {
            onRequestSort(event, property)
        }

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts'
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        sx={{
                            marginX: '4px'
                        }}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={true}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <Typography fontWeight={'bold'} fontSize='small'>
                                {headCell.label}
                            </Typography>
                            {orderBy === headCell.id
                                ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                )
                                : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell
                    align={'left'}
                    sx={{
                        marginX: '4px'
                    }}
                >
                    <Typography fontWeight={'bold'} fontSize='small'>
                        Actions
                    </Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    )
}

interface EnhancedTableToolbarProps {
    selectedData: Data[]
    groupName: string
    groupId: string
    rowIds: string[]
    setShowComparison: Dispatch<SetStateAction<boolean>>
    setSelected: Dispatch<SetStateAction<string[]>>
}

const EnhancedTableToolbar: FC<EnhancedTableToolbarProps> = ({ setShowComparison, selectedData, groupName, groupId, rowIds, setSelected }) => {
    const numSelected = selectedData.length
    const selected = selectedData.map(select => select.id)

    const removeFromGroup = (): void => {
        removePropertiesFromGroup(selected, groupId).then(() => { }).catch(err => console.log(err))
        setSelected([])
    }

    const [user] = useAuthState(auth)

    return (
        <Toolbar
            sx={{
                overflowX: 'auto',
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
                }),
                '>*': {
                    whiteSpace: 'nowrap'
                }
            }}
            className='table-scroll'
        >
            {numSelected > 0
                ? (
                    <>
                        <Typography
                            color="inherit"
                            variant="subtitle1"
                            component="div"
                        >
                            {numSelected} selected
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: '30px',
                                gap: '10px'
                            }}
                        >
                            {selectedData.length > 1 && selectedData.length < 7
                                ? <Button
                                    onClick={() => setShowComparison(true)}
                                    sx={{
                                        height: '30px',
                                        fontSize: { lg: '14px', sm: '10px' },
                                        letterSpacing: '0.1px',
                                        backgroundColor: '#0062FF',
                                        borderRadius: '10px',
                                        textTransform: 'none'
                                    }}
                                    variant="contained"
                                >
                                Compare
                                </Button>
                                : null}
                            <AddGroupModal selected={selected} setSelected={setSelected} />
                            <SharePropertyModal selectedData={selectedData} setSelected={setSelected} userName={user?.displayName ?? ''} />
                            {
                                groupId !== '' &&
                                <Button
                                    onClick={() => removeFromGroup()}
                                    sx={{
                                        height: '30px',
                                        fontSize: { lg: '14px', sm: '10px' },
                                        letterSpacing: '0.1px',
                                        borderRadius: '10px',
                                        textTransform: 'none'
                                    }}
                                    color="error"
                                    variant="contained"
                                    startIcon={<PlaylistRemove sx={{ fontSize: { lg: '12px', sm: '8px' } }} />}
                                >
                                    Remove from group
                                </Button>
                            }
                        </Box>
                    </>
                )
                : (
                    <>
                        <Typography
                            sx={{ marginRight: 'auto' }}
                            variant="h6"
                            id="tableTitle"
                            component="div"
                        >
                            {groupName}
                        </Typography>
                        {
                            groupId !== '' && <ShareGroupModal groupId={groupId} rowIds={rowIds} />
                        }
                    </>
                )}
        </Toolbar>
    )
}

interface PropertyTableProps {
    rows: Data[]
    groupName: string
    groupId: string
    selected: string[]
    setSelected: Dispatch<SetStateAction<string[]>>
    page: string
}

const PropertyTable: FC<PropertyTableProps> = ({ rows, groupName, groupId, selected, setSelected, page }) => {
    const [order, setOrder] = useState<Order>('asc')
    const [orderBy, setOrderBy] = useState<keyof Data>('address')

    const [showComparison, setShowComparison] = useState(false)
    const [selectedData, setSelectedData] = useState<Data[]>([])

    const navigate = useNavigate()

    const handleRequestSort = (
        event: MouseEvent<unknown>,
        property: keyof Data
    ): void => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>): void => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id)
            setSelected(newSelected)
            return
        }
        setSelected([])
    }

    const handleClick = (event: MouseEvent<unknown>, id: string): void => {
        const selectedIndex = selected.indexOf(id)
        let newSelected: string[] = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            )
        }

        setSelected(newSelected)
    }

    const isSelected = (id: string): boolean => selected.includes(id)

    useEffect(() => {
        const newSelectedData = rows.filter(row => selected.includes(row.id))
        setSelectedData(newSelectedData)
        if (newSelectedData.length < 2 || newSelectedData.length > 6) {
            setShowComparison(false)
        }
    }, [selected, rows])

    const propertySoftDelete = async (id: string) => {
        const propertyRef = doc(db, COLLECTION_NAMES.PROPERTIES_COLLECTION, id)
        await updateDoc(propertyRef, {
            isDeleted: true
        })
    }

    return (
        <Grid overflow='hidden' container spacing={2}>
            <Grid item xs={12}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <EnhancedTableToolbar setShowComparison={setShowComparison} selectedData={selectedData} groupName={groupName} groupId={groupId} rowIds={rows.map(row => row.id)} setSelected={setSelected} />
                    <TableContainer
                        className='table-scroll'
                        sx={{
                            width: '100%',
                            overflowX: 'scroll'
                        }}
                    >
                        <Table
                            aria-labelledby="tableTitle"
                            size={'small'}
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.sort(getComparator(order, orderBy)).slice() */}
                                {stableSort(rows, order, orderBy)
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row.id)
                                        const labelId = `enhanced-table-checkbox-${index}`
                                        const isDeleted = row.isDeleted
                                        return isDeleted
                                            ? null
                                            : (
                                                <TableRow
                                                    hover
                                                    onClick={(e) => {
                                                        if ((e.target as HTMLElement).tagName.toLowerCase() === 'input') return
                                                        navigate(`${page}/${row.id}`)
                                                    }}
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.id}
                                                    selected={isItemSelected}
                                                    sx={{
                                                        ':hover': {
                                                            cursor: 'pointer'
                                                        }
                                                    }}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            color="primary"
                                                            onClick={(event) => handleClick(event, row.id)}
                                                            checked={isItemSelected}
                                                            inputProps={{
                                                                'aria-labelledby': labelId
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        component="td"
                                                        id={labelId}
                                                        scope="row"
                                                        sx={{
                                                            marginX: '4px'
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                width: '110px',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap'
                                                            }}
                                                        >
                                                            {row.address}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell
                                                        align="left"
                                                        sx={{
                                                            minWidth: '110px',
                                                            marginX: '4px'
                                                        }}
                                                    >
                                                ${getValue(+row.cashFlow)}
                                                    </TableCell>
                                                    <TableCell
                                                        align="left"
                                                        sx={{
                                                            minWidth: '110px',
                                                            marginX: '4px'
                                                        }}
                                                    >
                                                ${getValue(+row.breakeven)}
                                                    </TableCell>
                                                    <TableCell
                                                        align="left"
                                                        sx={{
                                                            minWidth: '110px',
                                                            marginX: '4px'
                                                        }}
                                                    >
                                                ${getValue(+row.mortgage)}
                                                    </TableCell>
                                                    <TableCell
                                                        align="left"
                                                        sx={{
                                                            minWidth: '110px',
                                                            marginX: '4px'
                                                        }}
                                                    >
                                                ${getValue(+row.ownership)}
                                                    </TableCell>
                                                    <TableCell
                                                        align="left"
                                                        sx={{
                                                            minWidth: '110px',
                                                            marginX: '4px'
                                                        }}
                                                    >
                                                        <DeleteIcon onClick={e => { e.stopPropagation(); propertySoftDelete(row.id) }} sx={{ color: '#0062FF' }} />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
            {showComparison
                ? <Grid item xs={12}>
                    <Box sx={{ display: 'flex', gap: '10px', padding: '20px', background: 'white' }} >
                        {selectedData.map((property, i) => (
                            <Box sx={{ flexGrow: 1, padding: '10px', boxShadow: '#3c40434d 0px 1px 2px 0px, #3c404326 0px 2px 6px 2px;' }} key={i}>
                                <Typography><b>Address:</b> {property.address}</Typography>
                                <Typography><b>Cash Flow:</b> {property.cashFlow}</Typography>
                                <Typography><b>Breakeven:</b> {property.breakeven}</Typography>
                                <Typography><b>Mortgage:</b> {property.mortgage}</Typography>
                                <Typography><b>Ownership:</b> {property.ownership}</Typography>
                            </Box>
                        ))}
                    </Box>
                </Grid>
                : null}
        </Grid>
    )
}

export default PropertyTable
