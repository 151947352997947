import {
    TextField, TextFieldProps
} from '@mui/material'
import { FC, ReactElement } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

export type IFormInputProps = {
    name: string
    children?: ReactElement
} & TextFieldProps

export const FormInput: FC<IFormInputProps> = ({ children, name, ...otherProps }) => {
    const {
        control,
        formState: { errors }
    } = useFormContext()

    return (
        <Controller control={control} name={name} render={({ field }) => (
            children ?? <TextField
                {...otherProps}
                {...field}
                inputProps={{ inputMode: 'text' }}
                type='text'
                error={!(errors[name] == null)}
                // @ts-expect-error
                helperText={(errors[name] != null) ? errors[name]?.message : ''}
            />
        )}
        />
    )
}
