import {
    Box,
    Typography, Button
} from '@mui/material'
import { useForm, SubmitHandler } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Fragment, useState } from 'react'
import { BreakevenInput, BreakevenSchema } from './schema'
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import BreakevenFields from './BreakevenFields'
import { getBreakeven } from '../../../utils/calculations'

const Breakeven = () => {
    const [snackOpen, setSnackOpen] = useState(false)
    const [breakeven, setBreakeven] = useState('')

    const methods = useForm<BreakevenInput>({
        resolver: zodResolver(BreakevenSchema)
    })

    const {
        reset,
        handleSubmit,
        formState: { errors }
    } = methods

    const onSubmitHandler: SubmitHandler<BreakevenInput> = (values) => {
        const breakeven = getBreakeven(values)
        setBreakeven(breakeven)
    }

    // eslint-disable-next-line
    if (errors.root?.message) {
        console.error(errors)
        setSnackOpen(true)
        // setSnackMessage('please check invalid form fields')
    }

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }
        setSnackOpen(false)
    }

    const resetForm = () => {
        reset()
        setBreakeven('')
    }

    const action = (
        <Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </Fragment>
    )

    return (
        <Box>
            <Box textAlign='center' fontWeight='bold' sx={{ mb: '2rem' }}>
                Calculate the purchase price for a potential investment property that gives a specific monthly cash flow.
            </Box>
            <BreakevenFields onSubmitHandler={onSubmitHandler} handleSubmit={handleSubmit} methods={methods}>
                {breakeven.length > 0 &&
                        <Typography variant='h4' align='center'>Breakeven: {breakeven} $/month</Typography>
                }
                {/*  */}

                <Box display='flex' justifyContent='space-evenly'>
                    <Button
                        variant='contained'
                        type='submit'
                        sx={{ py: '0.8rem', mt: '1rem', width: '40%' }}
                    >
                            Calculate
                    </Button>

                    <Button
                        variant='contained'
                        color='secondary'
                        type='button'
                        sx={{ py: '0.8rem', mt: '1rem', width: '40%' }}
                        onClick={resetForm}
                    >
                            Clear
                    </Button>
                </Box>
            </BreakevenFields>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackOpen}
                autoHideDuration={6000}
                onClose={handleClose}
                message='LLLL'
                action={action}
            />
        </Box>
    )
}

export default Breakeven
