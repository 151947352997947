import { useState, FC, MouseEventHandler } from 'react'

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Divider,
    Typography,
    useMediaQuery,
    Button,
    Box
} from '@mui/material'

import styled from '@emotion/styled'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined'
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined'
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'

import ArrowLeft from '../../assets/JobDetail/ArrowLeft.png'
import CustomInput from '../../components/CustomInput'

import { useNavigate } from 'react-router'

// Importing firebase
import { db, auth } from '../../firebase'

import { collection, addDoc } from 'firebase/firestore'
import { useAuthState } from 'react-firebase-hooks/auth'
import theme from '../../theme'
import { toast } from 'react-toastify'
import { COLLECTION_NAMES } from '../../constants/firestore'

const SupportLinks: FC = () => {
    const navigate = useNavigate()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const [feedback, setFeedback] = useState<string>('')

    const [user] = useAuthState(auth)

    const handleFeedback: MouseEventHandler<HTMLButtonElement> = (e) => {
        if (feedback !== '') {
            if (user !== undefined && user !== null) {
                const feedBackData = {
                    name: user.displayName ?? 'anonymous',
                    email: user.email ?? 'anonymous',
                    uid: user.uid,
                    message: feedback
                }

                console.log(feedBackData)

                addDoc(collection(db, COLLECTION_NAMES.FEEDBACKS_COLLECTION), feedBackData)
                    .then(() => {
                        toast.success('Feedback Sent Successfully')
                        setFeedback('')
                    })
                    .catch((err) => {
                        console.error(err)
                        toast.error('Error Sending Feedback')
                    })
            }
        }
    }

    const StyledAccordion = styled(Accordion)(({ theme }) => ({
        boxShadow: 'none',
        border: 'none',
        marginTop: '8px',
        '& .MuiButtonBase-root, MuiAccordionSummary-root': {
            paddingY: isMobile ? '0px !important' : '10px !important',
            paddingX: isMobile ? '0px !important' : '20px !important'
        },
        '& .MuiAccordionSummary-content': {
            marginBottom: isMobile ? '4px !important' : '8px !important'
        }
    }))
    const iconWrapper = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f5f5f5',
        borderRadius: '5px',
        marginRight: { xs: '12px', sm: '20px' },
        width: { xs: '27px', sm: '35px' },
        height: { xs: '27px', sm: '35px' }
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: { lg: '26px', sm: '18px', xs: '18px' },
                    color: '#121212',
                    fontWeight: '700',
                    fontSize: { xs: '20px', sm: '24px' },
                    lineHeight: '32px',
                    letterSpacing: '0.1px'
                }}
            >
                <Box
                    onClick={() => navigate(-1)}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#171725',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.02);',
                        borderRadius: '50%',
                        cursor: 'pointer',
                        width: { lg: '46px', sm: '35px', xs: '30px' },
                        height: { lg: '46px', sm: '35px', xs: '30px' }
                    }}
                >
                    <Box component="img" src={ArrowLeft} />
                </Box>
                <Typography
                    variant="h2"
                    sx={{
                        fontWeight: '600',
                        fontSize: { lg: '24px', md: '20px', xs: '17px' },
                        lineHeight: '36px',
                        letterSpacing: '0.1px',
                        marginLeft: { sm: '18px', xs: '12px' },
                        color: '#171725'
                    }}
                >
                    Support
                </Typography>
            </Box>
            <Box sx={{ padding: '16px 0px' }}>
                <Typography
                    component='h3'
                    fontWeight='bold'
                    fontSize='large'
                    sx={{ color: '#7F879E' }}
                >
                    Main
                </Typography>
                <StyledAccordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={iconWrapper}>
                                <FeedOutlinedIcon
                                    fontSize={isMobile ? 'small' : 'medium'}
                                    sx={{ color: '#7a7a7a' }}
                                />
                            </Box>

                            <Typography
                                sx={{ fontWeight: '700', fontSize: { xs: '12px', sm: '17px' } }}
                            >
                                Survey
                            </Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={{ fontSize: { xs: '12px', sm: '16px' } }}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                            eget.
                        </Typography>
                    </AccordionDetails>
                </StyledAccordion>
                <StyledAccordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={iconWrapper}>
                                <CreditCardOutlinedIcon
                                    sx={{ color: '#7a7a7a' }}
                                    fontSize={isMobile ? 'small' : 'medium'}
                                />
                            </Box>

                            <Typography
                                sx={{ fontWeight: '700', fontSize: { xs: '12px', sm: '17px' } }}
                            >
                                Billing
                            </Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={{ fontSize: { xs: '12px', sm: '16px' } }}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                            eget.
                        </Typography>
                    </AccordionDetails>
                </StyledAccordion>
                <StyledAccordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={iconWrapper}>
                                <HelpOutlineOutlinedIcon
                                    sx={{ color: '#7a7a7a' }}
                                    fontSize={isMobile ? 'small' : 'medium'}
                                />
                            </Box>

                            <Typography
                                sx={{ fontWeight: '700', fontSize: { xs: '12px', sm: '17px' } }}
                            >
                                FAQ
                            </Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={{ fontSize: { xs: '12px', sm: '16px' } }}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                            eget.
                        </Typography>
                    </AccordionDetails>
                </StyledAccordion>
                <StyledAccordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={iconWrapper}>
                                <HelpOutlineOutlinedIcon
                                    sx={{ color: '#7a7a7a' }}
                                    fontSize={isMobile ? 'small' : 'medium'}
                                />
                            </Box>

                            <Typography
                                sx={{ fontWeight: '700', fontSize: { xs: '12px', sm: '17px' } }}
                            >
                                Notifications
                            </Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={{ fontSize: { xs: '12px', sm: '16px' } }}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                            eget.
                        </Typography>
                    </AccordionDetails>
                </StyledAccordion>
                <Divider />
                <Typography
                    component='h3'
                    fontWeight='bold'
                    fontSize='large'
                    sx={{
                        marginTop: { sm: '28px', xs: '18px' },
                        marginBottom: '10px',
                        color: '#7F879E'
                    }}
                >
                    Feedback
                </Typography>
                <CustomInput
                    placeHolder='Feedback'
                    type="text"
                    value={feedback}
                    onChange={e => setFeedback(e.target.value)}
                    required={true}
                    Icon={<RateReviewOutlinedIcon />}
                    endElement={
                        <Button
                            variant="contained"
                            onClick={handleFeedback}
                            sx={{ borderRadius: '12px', textTransform: 'none' }}
                        >
                            Submit
                        </Button>
                    }
                    InputStyles={{
                        background: '#F6F6F6',
                        borderRadius: '12px'
                    }}
                />
            </Box>
        </>
    )
}

export default SupportLinks
