export const propertyFormSteps = {
    propertyInfo: {
        text: 'Property Info',
        isCompleted: false
    },
    cashFlowInfo: {
        text: 'Cash Flow',
        isCompleted: false
    },
    breakevenInfo: {
        text: 'Breakeven',
        isCompleted: false
    },
    mortgageInfo: {
        text: 'Mortage',
        isCompleted: false
    },
    ownershipInfo: {
        text: 'Ownership',
        isCompleted: false
    },
    riskAssessmentInfo: {
        text: 'Risk Assessment',
        isCompleted: false
    }
}

export const propertyInfo = {
    streetAddress: '',
    city: '',
    state: '',
    zipCode: ''
}
export const cashFlowFields = {
    salesPrice: NaN,
    downPayment: NaN,
    closingCosts: NaN,
    mortgageTerm: NaN,
    interestRate: NaN,
    monthlyPMI: NaN,
    annualPropertyTaxes: NaN,
    annualPropertyInsurance: NaN,
    annualMaintenance: NaN,
    monthlyHOADues: NaN,
    monthlyUtilities: NaN,
    monthlyManagementFees: NaN,
    vacancyRate: NaN,
    monthlyRent: NaN
}
export const breakevenFields = {
    downPayment: NaN,
    closingCosts: NaN,
    mortgageTerm: NaN,
    interestRate: NaN,
    monthlyPMI: NaN,
    annualPropertyTaxes: NaN,
    annualPropertyInsurance: NaN,
    annualMaintenance: NaN,
    monthlyHOADues: NaN,
    monthlyUtilities: NaN,
    monthlyManagementFees: NaN,
    vacancyRate: NaN,
    monthlyRent: NaN,
    desiredCF: NaN
}
export const mortgageFields = {
    salesPrice: NaN,
    downPayment: NaN,
    closingCosts: NaN,
    mortgageTerm: NaN,
    interestRate: NaN,
    monthlyPMI: NaN
}
export const ownershipFields = {
    salesPrice: NaN,
    downPayment: NaN,
    closingCosts: NaN,
    mortgageTerm: NaN,
    interestRate: NaN,
    monthlyPMI: NaN,
    annualPropertyTaxes: NaN,
    annualPropertyInsurance: NaN,
    annualMaintenance: NaN,
    monthlyHOADues: NaN
}
export const NEW_PROPERTY = {
    propertyPhoto: '',
    propertyInfo,
    cashFlowInfo: {
        cashFlow: NaN,
        cashFlowFields
    },
    breakevenInfo: {
        breakeven: NaN,
        breakevenFields
    },
    mortgageInfo: {
        mortgage: NaN,
        mortgageFields
    },
    ownershipInfo: {
        ownership: NaN,
        ownershipFields
    }
}

export type Property = typeof NEW_PROPERTY
export type PropertyFormSteps = typeof propertyFormSteps
