/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { CSSProperties, MouseEvent } from 'react'
import { Link, NavigateFunction } from 'react-router-dom'
import { Box, Typography, IconButton, Avatar, Button } from '@mui/material'
import Logo from '../../assets/Navbars/transparent-app-logo.png'
import { User } from 'firebase/auth'
import HeaderLinks from './HeaderLinks'
import Profile from '../../assets/Navbars/Profile.png'
import { ROUTES } from '../../constants/routes'
import { ArrowDropDown } from '@mui/icons-material/'
import SigninUserNavBarMenu from './SigninUserNavBarMenu'

interface DesktopHeaderProps {
    anchorEl: null | HTMLElement
    handleClose: () => void
    handleAvatarClick: (event: MouseEvent<HTMLElement>) => void
    Loading: boolean
    logoutUser: () => void
    navigate: NavigateFunction
    open: boolean
    signedInUserData: User | null | undefined
    styles?: CSSProperties
    user: User | null | undefined
    userLoading: boolean
}
const DesktopHeader = ({
    anchorEl,
    handleAvatarClick,
    handleClose,
    Loading,
    logoutUser,
    navigate,
    open,
    signedInUserData,
    styles = {},
    user,
    userLoading
}: DesktopHeaderProps) => {
    return (
        <Box
            sx={{
                position: 'fixed',
                zIndex: 2,
                backgroundColor: 'white',
                height: '70px',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                padding: '10px 20px',
                gap: '10px',
                boxShadow: 'rgb(226 226 234) 0px -1px 0px inset',
                ...styles
            }}
        >
            <Box>
                <Link to={user === null ? ROUTES.LOGIN : ROUTES.OVERVIEW}>
                    <img src={Logo} alt="Logo" width={50} title="Logo" />
                </Link>
            </Box>
            <HeaderLinks user={user} userLoading />
            {(user != null)
                ? (
                    <>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {!Loading && (signedInUserData != null) && (
                                <>
                                    <Button
                                        onClick={user?.isAnonymous ? () => {} : handleAvatarClick}
                                        sx={{
                                            textAlign: 'left',
                                            padding: '0',
                                            borderRadius: '6px',
                                            textTransform: 'none',
                                            display: { xs: 'none', sm: 'flex' }
                                        }}
                                    >
                                        <div
                                            style={{
                                                backgroundImage: `url(${signedInUserData.photoURL ? signedInUserData.photoURL : Logo})`,
                                                width: '35px',
                                                height: '35px',
                                                borderRadius: '50%',
                                                backgroundSize: '100% 100%',
                                                marginRight: '10px'
                                            }}
                                        ></div>
                                        <Box component="span">
                                            <Typography
                                                component="p"
                                                sx={{
                                                    fontSize: { sm: '14px', xs: '12px' },
                                                    lineHeight: '14px',
                                                    fontWeight: '600',
                                                    letterSpacing: '0.1px',
                                                    color: '#171725',
                                                    margin: '0',
                                                    marginBottom: '5px',
                                                    whiteSpace: 'nowrap',
                                                    maxWidth: '130px',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }}
                                            >
                                                {signedInUserData.displayName}
                                            </Typography>
                                            <Typography component="p" sx={{ color: '#92929D', margin: '0', lineHeight: '12px', fontSize: '12px' }}>
                                                {signedInUserData.email}
                                            </Typography>
                                        </Box>
                                        {user?.isAnonymous
                                            ? (
                                                <></>
                                            )
                                            : (
                                                <ArrowDropDown
                                                    fontSize="large"
                                                    sx={{
                                                        color: '#92929D',
                                                        marginLeft: { sm: '20px', xs: '10px' }
                                                    }}
                                                />
                                            )}
                                    </Button>
                                    <IconButton
                                        size="small"
                                        onClick={handleAvatarClick}
                                        sx={{
                                            marginRight: '15px',
                                            width: '45px',
                                            height: '45px',
                                            display: { sm: 'none' }
                                        }}
                                    >
                                        <Avatar
                                            src={Profile}
                                            alt="name"
                                            sx={{
                                                width: '35px',
                                                height: '35px'
                                            }}
                                        ></Avatar>
                                    </IconButton>
                                </>
                            )}
                            {(signedInUserData != null) && !Loading
                                ? (
                                    <SigninUserNavBarMenu
                                        anchorEl={anchorEl}
                                        open={open}
                                        handleClose={handleClose}
                                        logoutUser={logoutUser}
                                        signedInUserData={signedInUserData}
                                        user={user}
                                        navigate={navigate}
                                    />
                                )
                                : null}
                        </Box>
                    </>
                )
                : null}
            <HeaderLinks isLoginSignupLinks user={user} userLoading={userLoading} />
        </Box>
    )
}

export default DesktopHeader
