import {
    signInAnonymously,
    updateProfile
} from 'firebase/auth'
import { auth } from '../firebase'
import { Dispatch, SetStateAction } from 'react'

export const signInAnonymous = (
    navigator: () => void,
    setHasAnonymousJobs: Dispatch<SetStateAction<boolean>>,
    setAnonymousJobs: Dispatch<SetStateAction<string[]>>
): void => {
    signInAnonymously(auth)
        .then(async (userCredential) => {
            setHasAnonymousJobs(false)
            setAnonymousJobs([])
            const newUser = userCredential.user
            await updateProfile(newUser, {
                displayName: 'Guest User'
            })
            navigator()
        })
        .catch((error) => {
            const errorCode = error.code
            const errorMessage: string = error.message
            console.log('Error Signing In Anonymously : ', errorCode, errorMessage)
            alert(`Error Signing In Anonymously : ${errorMessage}`)
        })
}
