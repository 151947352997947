/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { CardMembership, Support, Logout } from '@mui/icons-material'
import { Avatar, Box, Button, Divider, Menu, MenuItem, Typography } from '@mui/material'
import { User } from 'firebase/auth'
import { FC } from 'react'
import { NavigateFunction } from 'react-router'
import Logo from '../../assets/Navbars/transparent-app-logo.png'
import { ROUTES } from '../../constants/routes'

interface SigninUserNavBarMenuProps {
    anchorEl: null | HTMLElement
    open: boolean
    handleClose: () => void
    logoutUser: () => void
    signedInUserData: User | null | undefined
    user: User | null | undefined
    navigate: NavigateFunction
}

const SigninUserNavBarMenu: FC<SigninUserNavBarMenuProps> = ({ anchorEl, open, handleClose, logoutUser, signedInUserData, user, navigate }) => {
    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    width: '275px',
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0
                    }
                }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem>
                <Button
                    sx={{
                        textAlign: 'left',
                        marginRight: {
                            xl: '50px',
                            lg: '30px',
                            md: '20px',
                            xs: '10px'
                        },
                        borderRadius: '6px',
                        textTransform: 'none',
                        display: { xs: 'flex' },
                        // border: "1px solid red",
                        backgroundColor: 'transparent',
                        // change color on click
                        '&:active': {
                            backgroundColor: '#f5f5f5'
                        },
                        '&:hover': {
                            backgroundColor: '#f5f5f5'
                        }
                    }}
                >
                    <Avatar
                        sx={{
                            width: { sm: '32px', xs: '27px' },
                            height: { sm: '32px', xs: '27px' },
                            marginRight: '10px'
                        }}
                        src={signedInUserData?.photoURL ? signedInUserData?.photoURL : Logo}
                        alt={signedInUserData?.displayName ?? 'anonymous'}
                        title={signedInUserData?.displayName ?? 'anonymous'}
                    />
                    <Box component="span">
                        <Typography
                            component="p"
                            sx={{
                                fontSize: { sm: '14px', xs: '12px' },
                                lineHeight: '14px',
                                fontWeight: '600',
                                letterSpacing: '0.1px',
                                color: '#171725',
                                margin: '0',
                                marginBottom: '5px',
                                whiteSpace: 'nowrap',
                                width: '150px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                                // border: "1px solid #44444F",
                            }}
                        >
                            {signedInUserData?.displayName}
                        </Typography>
                        <Typography
                            component="p"
                            sx={{
                                color: '#92929D',
                                margin: '0',
                                lineHeight: '12px',
                                fontSize: '12px'
                            }}
                        >
                            {signedInUserData?.email}
                        </Typography>
                    </Box>
                </Button>
            </MenuItem>
            <Divider />

            {/* Support Menu Item */}
            <MenuItem onClick={() => navigate(ROUTES.SUPPORT)}>
                <Box
                    sx={{
                        width: '42px',
                        height: '42px',
                        background: '#0062FF',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50%',
                        marginRight: '10px'
                    }}
                >
                    <Support fontSize="small" sx={{ width: '22px', height: '22px', color: 'white' }} />
                </Box>
                <Typography
                    sx={{
                        fontWeight: '600',
                        fontSize: '14px',
                        lineHeight: '21px',
                        letterSpacing: '0.1px',
                        color: '#171725'
                    }}
                    variant="subtitle1"
                >Support</Typography>
            </MenuItem>
            {/* Settings Menu Item */}

            {/* Logout Menu Item */}
            <MenuItem onClick={() => logoutUser()}>
                <Box
                    sx={{
                        width: '42px',
                        height: '42px',
                        background: '#0062FF',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50%',
                        marginRight: '10px'
                    }}
                >
                    <Logout fontSize="small" sx={{ width: '22px', height: '22px', color: 'white' }} />
                </Box>
                <Typography
                    sx={{
                        fontWeight: '600',
                        fontSize: '14px',
                        lineHeight: '21px',
                        letterSpacing: '0.1px',
                        color: '#171725'
                    }}
                    variant="subtitle1"
                >Logout
                </Typography>
            </MenuItem>
        </Menu>
    )
}

export default SigninUserNavBarMenu
