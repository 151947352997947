import { createTheme } from '@mui/material/styles'

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: '#0062FF'
        },
        secondary: {
            main: '#19857b'
        },
        error: {
            main: '#FF0000'
        }
    }
})

export default theme
