import { FC, useState, useEffect, Dispatch, SetStateAction, useCallback } from 'react'

import {
    Box,
    List,
    ListItemButton,
    ListItemText
} from '@mui/material'
import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore'
import { Group } from '../../types'

interface GroupsListProps {
    groups: Array<QueryDocumentSnapshot<DocumentData>>
    setGroupId: Dispatch<SetStateAction<string>>
    setGroupName: Dispatch<SetStateAction<string>>
    setSelected: Dispatch<SetStateAction<string[]>>
}

const GroupsList: FC<GroupsListProps> = ({ groups, setGroupId, setGroupName, setSelected }) => {
    const [selectedId, setSelectedId] = useState<string>('')
    const [rows, setRows] = useState<Group[]>([])

    const handleListItemClick = useCallback(
        (id: string, name: string): void => {
            setSelectedId(id)
            setGroupId(id)
            setSelected([])
            setGroupName(name)
        },
        [setGroupId, setGroupName, setSelectedId, setSelected]
    )

    useEffect(() => {
        const newRows: Group[] = groups.map(group => ({
            id: group.id,
            name: group.data().name
        }))
        setRows([{ id: '', name: 'All Properties' }, ...newRows])
    }, [groups])

    useEffect(() => {
        if (!(rows.map(row => row.id).includes(selectedId))) {
            handleListItemClick(rows[0]?.id, rows[0]?.name)
        }
    }, [rows, handleListItemClick, selectedId])

    return (
        <Box sx={{ height: 'fit-content', width: 'fit-content', maxWidth: '200px', bgcolor: 'background.paper', borderRadius: '4px', boxShadow: '2px 3px 3px rgba(0,0,0,0.2)' }}>
            <List component="nav" aria-label="main mailbox folders">
                {
                    rows.map(row => (
                        <ListItemButton
                            key={row.id}
                            selected={selectedId === row.id}
                            onClick={() => handleListItemClick(row.id, row.name)}
                        >
                            <ListItemText sx={{
                                minWidth: '110px'
                            }} primary={row.name} />
                        </ListItemButton>
                    ))
                }
            </List>
        </Box>
    )
}

export default GroupsList
