import React, { CSSProperties, FC, ReactNode } from 'react'
import { ButtonBack, CarouselProvider, Slider, ButtonNext } from 'pure-react-carousel'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import 'pure-react-carousel/dist/react-carousel.es.css'
import './PureReactCarousel.css'

interface PureReactCarouselProps {
    children: ReactNode
    totalSlides: number
    visibleSlides: number
    naturalSlideWidth?: number
    naturalSlideHeight?: number
    step?: number
    infinite?: boolean
    currentSlide?: number | undefined
    isIntrinsicHeight?: boolean
    showCarouselButtons?: boolean
    stretchSlidesToFullWidth?: boolean
    CarouselProviderClassName?: string
    CarouselContainerClassName?: string
    sliderTrayClassName?: string
    carouselBackButtonClassName?: string
    carouselNextButtonClassName?: string
    BackButtonContent?: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
    NextButtonContent?: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
    BackButtonStyles?: CSSProperties
    NextButtonStyles?: CSSProperties
}

const PureReactCarousel: FC<PureReactCarouselProps> = ({
    children,
    totalSlides,
    visibleSlides,
    naturalSlideWidth = null,
    naturalSlideHeight = null,
    step,
    infinite,
    currentSlide = undefined,
    isIntrinsicHeight = true,
    showCarouselButtons = true,
    stretchSlidesToFullWidth = false,
    CarouselProviderClassName = '',
    CarouselContainerClassName = '',
    sliderTrayClassName = '',
    carouselBackButtonClassName = '',
    carouselNextButtonClassName = '',
    BackButtonContent = null,
    NextButtonContent = null,
    BackButtonStyles = {},
    NextButtonStyles = {}
}) => {
    const CarouselProviderProps = {
        naturalSlideWidth: naturalSlideWidth ?? 100,
        naturalSlideHeight: naturalSlideHeight ?? 0,
        totalSlides,
        visibleSlides: stretchSlidesToFullWidth ? (visibleSlides > totalSlides ? totalSlides : visibleSlides) : visibleSlides,
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        step: step ?? Math.floor(visibleSlides || 1),
        dragEnabled: totalSlides > visibleSlides && !showCarouselButtons,
        isIntrinsicHeight,
        infinite,
        className: `${CarouselProviderClassName}`,
        ...(currentSlide === undefined ? {} : { currentSlide })
    }

    return (
        <>
            <CarouselProvider {...CarouselProviderProps}>
                <div className={`${CarouselContainerClassName}`}>
                    {showCarouselButtons && (
                        <ButtonBack style={{ ...BackButtonStyles }} className={`goBackSlide ${carouselBackButtonClassName}`}>
                            <>{BackButtonContent ?? <ArrowBackIosIcon />}</>
                        </ButtonBack>
                    )}
                    <Slider className="slider" id="jnnnnnun" classNameTray={`${sliderTrayClassName}`}>
                        {children}
                    </Slider>
                    {showCarouselButtons && (
                        <ButtonNext style={{ ...NextButtonStyles }} className={`${carouselNextButtonClassName ?? 'goNextSlide'}`}>
                            <>{NextButtonContent ?? <ArrowForwardIosIcon />}</>
                        </ButtonNext>
                    )}
                </div>
            </CarouselProvider>
        </>
    )
}
export default PureReactCarousel
