import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { UseFormReturn } from 'react-hook-form'
import { OwnershipInput } from '../../Calculators/Ownersip/schema'
import OwnershipFields from '../../Calculators/Ownersip/OwnershipFields'

interface Props {
    methods: UseFormReturn<OwnershipInput>
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const OwnershipForm: FC<Props> = ({ methods, ...restProps }) => {
    return (
        <Box
            id="ownershipInfo"
        >
            <Typography
                variant="h3"
                sx={{
                    fontWeight: '600',
                    fontSize: { lg: '24px', md: '20px', xs: '18px' },
                    marginTop: '10px'
                }}
            >
                Ownership
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '40px',
                    gap: '30px'
                }}
            >
                <OwnershipFields methods={methods} {...restProps} />
            </Box>
        </Box >
    )
}

export default OwnershipForm
