import { Link, useLocation } from 'react-router-dom'
import { HolidayVillage, Insights, PhoneInTalk, Calculate } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { ROUTES } from '../../constants/routes'
import { FC } from 'react'

const navLinks = [
    { icon: Calculate, text: 'Calculators', to: ROUTES.CALCULATORS },
    { icon: HolidayVillage, text: 'Properties', to: ROUTES.PROPERTIES },
    { icon: Insights, text: 'Analysis', to: ROUTES.PROPERTY_ANALYSIS },
    { icon: PhoneInTalk, text: 'Support', to: ROUTES.SUPPORT }
]

const NavLinks: FC = () => {
    const location = useLocation()
    return (
        <Box>
            <Box sx={{ paddingBottom: '1px', marginBottom: '-20px' }}>
                {navLinks.map((l) => (
                    <Link key={l.to} style={{ textDecoration: 'none' }} to={l.to}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '28px'
                            }}
                        >
                            {(location.pathname.startsWith(l.to) || (l.to === ROUTES.CALCULATORS && location.pathname === ROUTES.OVERVIEW)) && (
                                <Box
                                    sx={{
                                        borderRadius: ' 0px 100px 100px 0px;',
                                        backgroundColor: '#0062FF',
                                        width: '3px',
                                        height: '32px'
                                    }}
                                ></Box>
                            )}
                            <l.icon
                                sx={{
                                    marginX: '22px',
                                    color: location.pathname.startsWith(l.to) || (l.to === ROUTES.CALCULATORS && location.pathname === ROUTES.OVERVIEW) ? '#0062FF' : 'grey',
                                    width: '20px',
                                    height: '20px'
                                }}
                            />
                            <Typography
                                component="p"
                                sx={{
                                    margin: '0',
                                    fontWeight: '500',
                                    fontSize: '14px',
                                    lineHeight: '21px',
                                    color: location.pathname.startsWith(l.to) || (l.to === ROUTES.CALCULATORS && location.pathname === ROUTES.OVERVIEW) ? '#0062FF' : '#171725',
                                    '&:hover,:active, &:focus': {
                                        fontWeight: '600'
                                    }
                                }}
                            >
                                {l.text}
                            </Typography>
                        </Box>
                    </Link>
                ))}
            </Box>
        </Box>
    )
}

const LeftNavbar: FC = () => {
    return (
        <>
            <Box
                className="nav-wrapper"
                sx={{
                    boxShadow: 'inset -1px 0px 0px #E2E2EA;',
                    background: 'white',
                    display: { xs: 'none', sm: 'unset' },
                    minWidth: { md: '247px', sm: '180px' },
                    minHeight: screen.height - 70,
                    position: 'relative',
                    padding: '15px 0'
                }}
            >
                <Box sx={{ position: 'fixed' }}>
                    <NavLinks />
                </Box>
            </Box>
        </>
    )
}

export default LeftNavbar
