import { object, TypeOf, z } from 'zod'

export const PropertyInfoSchema = object({
    streetAddress: z.string().refine(data => data.trim() !== '', {
        message: 'Street Address must not be empty'
    }),
    city: z.string().refine(data => data.trim() !== '', {
        message: 'City must not be empty'
    }),
    state: z.string().refine(data => data.trim() !== '', {
        message: 'State must not be empty'
    }),
    zipCode: z.string().refine(data => data.trim() !== '', {
        message: 'Zip Code must not be empty'
    })
})

export type PropertyInfoInput = TypeOf<typeof PropertyInfoSchema>
