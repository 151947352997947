export const ROUTES = {
    LOGIN: '/login',
    SIGNUP: '/signup',
    PROFILE: '/profile',
    SUPPORT: '/support',
    OVERVIEW: '/',
    PROPERTIES: '/properties',
    PROPERTY_ANALYSIS: '/analysis',
    ANALYSIS: '/analysis/:id',
    PROPERTY_FORM: '/properties/:id',
    CALCULATIONS: '/calc',
    CALCULATORS: '/calculators',
    SERVICES: '/services',
    SERVICES_FEEDBACK: '/services/feedback',
    SERVICE_DETAIL: '/services/:slug',
    BLOGS: '/blogs',
    BLOGS_DETAILS: '/blogs/:category/:slug',
    NOT_FOUND: '*'
}

export const PATHS_WITHOUT_AUTH = [
    ROUTES.LOGIN, ROUTES.SIGNUP
]
