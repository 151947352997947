import { FC } from 'react'
import { Typography, Box, Button, useMediaQuery, CircularProgress } from '@mui/material'
import PropertyList from '../../components/Property/PropertyList'
import { Add } from '@mui/icons-material'
import { addNewProperty } from '../../utils/property'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'
import { useCollection } from 'react-firebase-hooks/firestore'
import { auth } from '../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import theme from '../../theme'
import { getPropertiesQuery } from '../../utils/queries'

// interface Props { }

const Properties: FC = () => {
    const navigate = useNavigate()

    const [user] = useAuthState(auth)

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const handleNewPropertyBtnClick = (): void => {
        addNewProperty(user?.uid, user?.isAnonymous, user?.email).then((id: string) => {
            navigate(ROUTES.PROPERTIES + `/${id}`)
        }).catch(err => console.log(err))
    }

    const propertiesQuery = getPropertiesQuery()

    const [properties, propertiesLoading] = useCollection(propertiesQuery)

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography
                    variant="h2"
                    sx={{
                        fontWeight: '600',
                        fontSize: { lg: '24px', md: '20px', xs: '18px' },
                        lineHeight: '36px',
                        letterSpacing: '0.1px',
                        marginBottom: { lg: '30px', xs: '15px' }
                    }}
                >
                    Properties
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    {!isMobile && <Button
                        onClick={handleNewPropertyBtnClick}
                        sx={{
                            height: '50px',
                            fontSize: { lg: '14px', sm: '12px' },
                            lineHeight: '21px',
                            letterSpacing: '0.1px',
                            backgroundColor: '#0062FF',
                            borderRadius: '10px',
                            marginRight: '30px',
                            textTransform: 'none'
                        }}
                        variant="contained"
                        startIcon={<Add />}
                    >
                        New Property
                    </Button>}
                </Box>
            </Box>
            {
                propertiesLoading
                    ? (
                        <Box
                            width='100%'
                            sx={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    )
                    : <PropertyList properties={properties?.docs ?? []} page={ROUTES.PROPERTIES} />
            }
        </>
    )
}

export default Properties
