import { useState, useEffect, FC } from 'react'
// Importing MUI
import { Box, Typography, useMediaQuery } from '@mui/material'

import { motion } from 'framer-motion'

// Importing firebase
import { auth } from '../../firebase'

// Importing for navigating to other pages
import { useNavigate } from 'react-router'

// Importing Styles
import '../../index.css'

// Importing Images
import bannerImage from '../../assets/Login/banner.png'

// Importing Components
import theme from '../../theme'
import LoginModal from '../../components/Auth/LoginModal'

import { useAuthState } from 'react-firebase-hooks/auth'
import { useSearchParams } from 'react-router-dom'
import AuthModal from '../../components/Auth/AuthModal'

const container = {
    show: {
        transition: {
            staggerChildren: 0.05
        }
    }
}
const item = {
    hidden: { opacity: 0, y: 40 },
    show: { opacity: 1, y: 0 }
}
const Login: FC = () => {
    // navigate hook
    const navigate = useNavigate()

    const [searchParams] = useSearchParams()

    let callback = searchParams.get('callback')

    if (callback !== null) callback = decodeURIComponent(callback)

    const [openModal, setOpenModal] = useState(false)
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const [user, userLoading] = useAuthState(auth)

    useEffect(() => {
        console.log('develop')

        if (userLoading) return
        if (user !== null) {
            if (callback !== null) navigate(callback)
            else navigate('/')
        }
    }, [user, userLoading, callback, navigate])

    return (
        <>
            {!isMobile
                ? (
                    <Box
                        component={motion.div}
                        sx={{
                            backgroundImage: `url(${bannerImage})`,
                            background: '#C4C4Cf4',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            width: 1,
                            height: { lg: 580, md: 450, xs: 450 },
                            marginTop: '-45px',
                            borderRadius: '0px 0px 74px 74px'
                            // x, opacity
                            // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.7)'
                        }}
                    >
                        <Box
                            component={'div'}
                            sx={{
                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: 1,
                                height: { lg: 580, md: 450, xs: 450 },
                                borderRadius: '0px 0px 74px 74px'
                            }}
                        >
                            <Box
                                component={'div'}
                            >
                                <Typography
                                    component="h3"
                                    sx={{
                                        height: { lg: '200px', md: '150px' },
                                        marginTop: { lg: '140px', xs: '110px' },
                                        marginLeft: { sm: '15%', xs: '5%' },
                                        marginRight: { sm: '15%', xs: '5%' },
                                        fontFamily: 'Poppins',
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        fontSize: { lg: '56px', md: '36px', sm: '30px', xs: '25px' },
                                        lineHeight: '10px',
                                        // border: "1px solid #FFFFFF",
                                        textAlign: 'center',
                                        letterSpacing: '0.233333px',
                                        color: '#FAFAFB'
                                    }}
                                >
                                    Welcome to the Professional Community
                                </Typography>
                                <AuthModal
                                    callback={callback}
                                    isMobile={isMobile}
                                />
                            </Box>
                        </Box>
                    </Box>
                )
                : (
                    <Box
                        component={motion.div}
                        sx={{
                            backgroundImage: `url(${bannerImage})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            width: '100%',
                            minHeight: '100vh',
                            position: 'relative'
                            // height: "100vh",
                            // x, opacity
                            // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.7)'
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: 'rgba(21, 33, 74, 0.7);',
                                position: 'absolute',
                                left: '0',
                                top: '0',
                                bottom: '0',
                                width: '100%',
                                minHeight: '100vh',
                                zIndex: '2'
                            }}
                        ></Box>
                        <motion.div variants={container} initial="hidden" animate="show">
                            <Box
                                component={motion.div}
                                variants={item}
                                sx={{
                                    paddingTop: '180px',
                                    zIndex: '3',
                                    position: 'relative',
                                    paddingBottom: '25px'
                                }}
                            >
                                <Box
                                    className="login__mob-heading"
                                    sx={{ paddingX: '45px', marginBottom: '37px' }}
                                >
                                    <Typography
                                        variant="h1"
                                        sx={{
                                            color: 'white',
                                            fontWeight: '700',
                                            fontSize: '24px',
                                            lineHeight: '36px',
                                            textAlign: 'center'
                                        }}
                                    >
                                        Welcome To the Professional Community
                                    </Typography>
                                </Box>
                                <AuthModal
                                    callback={callback}
                                    isMobile={isMobile}
                                    setOpenModal={setOpenModal}
                                />
                            </Box>
                        </motion.div>

                        <LoginModal
                            openModal={openModal}
                            setOpenModal={setOpenModal}
                        />
                    </Box>
                )}
        </>
    )
}
export default Login
