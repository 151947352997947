import { FC, Dispatch, SetStateAction } from 'react'
import { MEMBERSHIPS } from '../../../constants'
import {
    Box,
    Typography
} from '@mui/material'

interface Props {
    value: number
    setValue: Dispatch<SetStateAction<number>>
}

const ShowMemberships: FC<Props> = ({ value, setValue }) => {
    return <>
        {MEMBERSHIPS.map(membership => (
            <Box
                key={membership.id}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: membership.id === value ? '#0062FF' : 'silver',
                    padding: '5px',
                    borderRadius: '5px',
                    width: '120px',
                    backgroundColor: membership.id === value ? '#d9d9d9' : 'white',
                    cursor: 'pointer',
                    ':hover': {
                        backgroundColor: membership.id === value ? '#d9d9d9' : '#efefef'
                    }
                }}
                onClick={() => setValue(membership.id)}
            >
                <Typography
                    color='Highlight'
                >
                    {membership.name}
                </Typography>
                <Typography>
                    ${membership.price}/month
                </Typography>
            </Box>
        ))}
    </>
}

export default ShowMemberships
