import { FC } from 'react'

import { CircularProgress, Box, Typography } from '@mui/material'

import logo from '../../assets/Navbars/Logo.jpg'

const CustomLoader: FC = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                mt: '30px'
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', mt: '100px' }}>
                <Box
                    sx={{ display: 'block', width: '100px' }}
                    component="img"
                    src={logo}
                    alt="logo"
                    title="Logo"
                />
            </Box>
            <Typography
                sx={{
                    color: 'text.dark',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: { md: '24px', sm: '20px', xs: '18px' },
                    lineHeight: '36px',
                    letterSpacing: '0.15px'
                }}
                component={'p'}
            >
                Nest Swift
            </Typography>
            <Box
                sx={{
                    marginTop: '20px'
                }}
                component={'div'}
            >
                <CircularProgress color="primary" />
            </Box>
        </Box>
    )
}
export default CustomLoader
