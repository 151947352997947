import {
    TextField, TextFieldProps
} from '@mui/material'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

export type IFormInputProps = {
    name: string
} & TextFieldProps

export const CalculatorFormInput: FC<IFormInputProps> = ({ name, onChange, ...otherProps }) => {
    const {
        control,
        setValue,
        formState: { errors }
    } = useFormContext()

    const inputFocusHandler = ({ target: { value } }: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
        if (+value === 0 || isNaN(+value)) {
            setValue(name, '')
        }
    }

    return (
        <Controller control={control} name={name} render={({ field }) => (
            <TextField
                {...otherProps}
                {...field}
                {...((onChange != null) ? { onChange } : {})}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                type='number'
                error={!(errors[name] == null)}
                onFocus={inputFocusHandler}
                // @ts-expect-error
                helperText={(errors[name] != null) ? errors[name]?.message : ''}
            />
        )}
        />
    )
}
