import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Modal,
    Radio,
    RadioGroup,
    SxProps,
    TextField,
    Typography
} from '@mui/material'
import { useState, FC, Dispatch, SetStateAction } from 'react'
import { Add } from '@mui/icons-material'
import { useCollection } from 'react-firebase-hooks/firestore'
import { addDoc, arrayUnion, collection, doc, query, setDoc, where } from 'firebase/firestore'
import { auth, db } from '../../../../firebase'
import { COLLECTION_NAMES } from '../../../../constants/firestore'
import { useAuthState } from 'react-firebase-hooks/auth'

interface ShareModalProps {
    selected: string[]
    setSelected: Dispatch<SetStateAction<string[]>>
}

const MODAL_STYLE: SxProps = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 3,
    p: 2,
    outline: 0
}

const AddGroupModal: FC<ShareModalProps> = ({ selected, setSelected }) => {
    const [open, setOpen] = useState<boolean>(false)
    const [groupName, setGroupName] = useState<string>('')
    const [groupId, setGroupId] = useState<string>('')
    const [error, setError] = useState<boolean>(false)
    const [isExisting, setIsExisting] = useState<boolean>(false)

    const [user] = useAuthState(auth)

    const [groups, groupsLoading] = useCollection(
        query(collection(db, COLLECTION_NAMES.GROUPS_COLLECTION), where('uid', '==', user?.uid ?? ''))
    )

    const closeModal = (): void => {
        setOpen(false)
    }

    const addGroupHandler = (): void => {
        addPropertiesToGroup().then(() => { }).catch(e => console.log(e))
        setSelected([])
    }

    const addPropertiesToGroup = async (): Promise<void> => {
        let promises: Array<Promise<void>> = []
        if (isExisting) {
            promises = selected.map(async select => await setDoc(doc(db, COLLECTION_NAMES.PROPERTIES_COLLECTION, select), {
                groups: arrayUnion(groupId)
            }, { merge: true }))
        } else {
            const groupNames = groups?.docs.map(group => group.data().name) ?? []
            if (groupNames.includes(groupName)) {
                setError(true)
            } else {
                const res = await addDoc(collection(db, COLLECTION_NAMES.GROUPS_COLLECTION), {
                    uid: user?.uid,
                    name: groupName,
                    sharedWith: user !== undefined && user !== null ? user.isAnonymous ? [] : [user.email] : []
                })
                promises = selected.map(async select => await setDoc(doc(db, COLLECTION_NAMES.PROPERTIES_COLLECTION, select), {
                    groups: arrayUnion(res.id)
                }, { merge: true }))
            }
        }
        if (promises.length !== 0) {
            await Promise.all(promises)
            closeModal()
        }
    }

    return (
        <>
            <Button
                onClick={() => setOpen(true)}
                sx={{
                    height: '30px',
                    fontSize: { lg: '14px', sm: '10px' },
                    letterSpacing: '0.1px',
                    backgroundColor: '#0062FF',
                    borderRadius: '10px',
                    textTransform: 'none'
                }}
                variant="contained"
                startIcon={<Add sx={{ fontSize: { lg: '12px', sm: '8px' } }} />}
            >
                Add to group
            </Button>
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={MODAL_STYLE}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                            mb: 4,
                            mt: 0,
                            fontWeight: 'bold'
                        }}
                    >
                        Add Properties to Group
                    </Typography>
                    <Divider />
                    {
                        isExisting
                            ? (
                                <>
                                    {
                                        groups !== undefined && groups.docs.length !== 0
                                            ? (
                                                <>
                                                    <FormControl>
                                                        <FormLabel id="demo-radio-buttons-group-label">Groups</FormLabel>
                                                        <RadioGroup
                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                            defaultValue="female"
                                                            name="radio-buttons-group"
                                                            value={groupId}
                                                            onChange={e => {
                                                                setError(false)
                                                                setGroupId(e.target.value)
                                                            }}
                                                        >

                                                            {
                                                                groups.docs.map(group => (
                                                                    <>
                                                                        <FormControlLabel key={group.id} value={group.id} control={<Radio />} label={group.data().name} />
                                                                    </>
                                                                ))
                                                            }
                                                        </RadioGroup>
                                                    </FormControl>
                                                </>
                                            )
                                            : (
                                                <>
                                                    {
                                                        !groupsLoading &&
                                                        <Typography>
                                                            No existing groups found
                                                        </Typography>
                                                    }
                                                </>
                                            )
                                    }
                                </>
                            )
                            : (
                                <>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        Group Name:
                                    </Typography>
                                    <TextField
                                        sx={{
                                            fontSize: '12px'
                                        }}
                                        inputProps={{
                                            style: {
                                                paddingBottom: '6px',
                                                paddingTop: '6px'
                                            }
                                        }}
                                        type="email"
                                        placeholder="abc@example.com"
                                        value={groupName}
                                        onChange={(e) => {
                                            setError(false)
                                            setGroupName(e.target.value)
                                        }}
                                    />
                                </>
                            )
                    }
                    {
                        groups !== undefined && groups.docs.length !== 0 && <Box>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={isExisting}
                                    onChange={e => setIsExisting(e.target.checked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />}
                                label='Add to existing group?'
                            />
                        </Box>
                    }

                    {error && (
                        <Typography
                            sx={{
                                mt: 1,
                                fontSize: '12px'
                            }}
                            color="red"
                        >
                            Group Name already exists
                        </Typography>
                    )}
                    <Divider
                        sx={{
                            mt: 4
                        }}
                    />
                    <Box
                        sx={{
                            mt: 4,
                            display: 'flex',
                            justifyContent: 'right'
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                marginRight: '12px'
                            }}
                            onClick={addGroupHandler}
                            color="info"
                        >
                            Add
                        </Button>
                        <Button variant="contained" color="error" onClick={closeModal}>
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default AddGroupModal
