import { FC, SyntheticEvent, useContext, useState } from 'react'
// Importing MUI
import { Box, Typography, Button, Link } from '@mui/material'
import { motion } from 'framer-motion'

// Importing firebase
import {
    createUserWithEmailAndPassword,
    updateProfile as updateProfileFirebase
} from 'firebase/auth'
import { auth } from '../../firebase'

// Importing for navigating to other pages
import { useNavigate } from 'react-router'

// Importing Images
import bannerImage from '../../assets/Login/banner.png'
import nextImage from '../../assets/Login/arrow_right.png'

// For Animating the texts
import AnimatedText from 'react-animated-text-content'

// Importing Icons
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import PersonIcon from '@mui/icons-material/Person'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import CustomInput from '../../components/CustomInput'

// Importing Styles
import '../../index.css'
import { useAuthState } from 'react-firebase-hooks/auth'
import { ROUTES } from '../../constants/routes'
import { useSearchParams } from 'react-router-dom'
import { signInAnonymous } from '../../utils/authentication'
// import SocialAuth from '../../components/SocialAuth'
import { AnonymousJobsCTX } from '../../contexts/anonymousJobs'
import ShowMemberships from '../../components/MemberShip/ShowMemberships'

// const SignUp: FC = () => {
const SignUp: FC = () => {
    const [searchParams] = useSearchParams()

    let callback = searchParams.get('callback')

    if (callback !== null) callback = decodeURIComponent(callback)
    // navigate hook
    const navigate = useNavigate()

    const { setHasAnonymousJobs, setAnonymousJobs } =
        useContext(AnonymousJobsCTX)

    // const x = useMotionValue(0)
    // const opacity = useTransform(x, [-200, 0, 200], [0, 1, 0])

    // Defining States
    const [name, setName] = useState<string>('')
    const [username, setUsername] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')
    const [membership, setMembership] = useState(1)
    // const [feedback, setFeedback] = useState<string>("");
    const [user, userLoading] = useAuthState(auth)

    const navigator = (): void => {
        if (callback !== null) navigate(callback)
        else navigate('/')
    }

    // Sign Up Function
    const handleSignUpWithEmail = (e: SyntheticEvent): void => {
        e.preventDefault()

        // if (password.length < 6) {
        //     alert("Password must be at least 6 characters long");
        //     return;
        // }

        if (password !== confirmPassword) {
            alert('Passwords do not match')
            return
        }

        if (
            name === '' ||
            username === '' ||
            email === '' ||
            password === '' ||
            confirmPassword === ''
        ) {
            // alert("Please fill all the fields");

        } else {
            createUserWithEmailAndPassword(auth, email, password)
                .then(async (userCredential) => {
                    // Signed in
                    const newUser = userCredential.user
                    await updateProfileFirebase(newUser, {
                        displayName: username
                    })
                    navigator()
                })
                .catch((error: Error) => {
                    const errorCode = (error as any).code
                    const errorMessage = error.message
                    console.log('error is: ', errorCode, errorMessage)
                    alert(`Error Signing Up: ${errorMessage}`)
                    // ..
                })
        }
    }

    // Anonymous Login
    const handleAnonymousSignIn = (): void => {
        signInAnonymous(navigator, setHasAnonymousJobs, setAnonymousJobs)
    }

    return (
        <Box
            component={motion.div}
            // drag="x"
            // dragConstraints={{ left: -100, right: 100 }}
            // drag="x"
            // whileHover={{
            //     scale: 1.2,
            //     transition: { duration: 0.3 }
            // }}
            // whileTap={{ scale: 0.9 }}
            sx={{
                backgroundImage: `url(${bannerImage})`,
                background: '#C4C4Cf4',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                width: 1,
                height: { lg: 580, md: 450, xs: 450 },
                marginTop: '-45px',
                borderRadius: '0px 0px 74px 74px'
                // x, opacity
                // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.7)'
            }}
        >
            <Box
                component={'div'}
                sx={{
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: 1,
                    height: { lg: 580, md: 450, xs: 450 },
                    marginTop: '-45px',
                    borderRadius: '0px 0px 74px 74px'
                }}
            >
                <Box
                    component={'div'}
                    sx={{
                        // border: "5px solid #000000",
                        width: 1,
                        height: 1
                    }}
                >
                    <Typography
                        variant="h3"
                        component="div"
                        sx={{
                            height: { lg: '200px', md: '150px' },
                            marginTop: { lg: '140px', xs: '110px' },
                            marginLeft: { sm: '15%', xs: '5%' },
                            marginRight: { sm: '15%', xs: '5%' },
                            fontFamily: 'Poppins',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            fontSize: { lg: '56px', md: '36px', sm: '30px', xs: '25px' },
                            lineHeight: '10px',
                            // border: "1px solid #FFFFFF",
                            textAlign: 'center',
                            letterSpacing: '0.233333px',
                            color: '#FAFAFB'
                        }}
                        gutterBottom
                    >
                        <AnimatedText
                            type="words" // animate words or chars
                            animation={{
                                x: '200px',
                                y: '-20px',
                                scale: 1.1,
                                ease: 'ease-in-out'
                            }}
                            animationType="lights"
                            interval={0.2}
                            duration={1}
                            tag="p"
                            className="animated-paragraph"
                            includeWhiteSpaces
                            threshold={0.2}
                        // rootMargin="20%"
                        >
                            Welcome to the
                        </AnimatedText>
                        <AnimatedText
                            type="chars" // animate words or chars
                            animation={{
                                x: '200px',
                                y: '-20px',
                                scale: 1.1,
                                ease: 'ease-in-out'
                            }}
                            animationType="rifle"
                            interval={0.03}
                            duration={1}
                            tag="p"
                            className="animated-paragraph"
                            includeWhiteSpaces
                            threshold={0.1}
                        // rootMargin="20%"
                        >
                            Professional Community
                        </AnimatedText>
                    </Typography>
                    <Box
                        component={'div'}
                        sx={{
                            width: { md: '560px', sm: '450px', xs: '90%' },
                            height: { sm: 'auto' },
                            margin: '0 auto',
                            paddingBottom: '0px',
                            // border: "1px solid #000000",
                            background: '#FFFFFF',
                            boxShadow: '3px -5px 40px rgba(205, 205, 212, 0.1)',
                            borderRadius: '20px',
                            padding: { sm: '30px', xs: '15px' }
                        }}
                    >
                        {!userLoading && (user === undefined || user === null) && (
                            <Box component="div">
                                <Box component={'div'}>
                                    <Typography
                                        component="div"
                                        sx={{
                                            width: '100%',
                                            textAlign: 'center',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Typography
                                            variant="h5"
                                            component="div"
                                            sx={{
                                                fontFamily: "'Poppins'",
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                fontSize: { md: '24px', sm: '20px', xs: '16px' },
                                                lineHeight: '36px',
                                                letterSpacing: '0.15px',
                                                color: '#171725'
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: '#0062FF',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                    fontSize: { md: '24px', sm: '20px', xs: '18px' },
                                                    lineHeight: '36px',
                                                    letterSpacing: '0.15px'
                                                }}
                                                component={'span'}
                                            >
                                                SignUp Page
                                            </Typography>
                                        </Typography>

                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Box
                                                onClick={() => handleAnonymousSignIn()}
                                                component={'img'}
                                                sx={{
                                                    display: 'block',
                                                    marginLeft: '14px',
                                                    width: { sm: '36px', xs: '24px' },
                                                    height: { sm: '36px', xs: '24px' },
                                                    cursor: 'pointer'
                                                }}
                                                src={nextImage}
                                                title={'Continue as anonymous'}
                                                alt="next"
                                            />
                                        </Box>
                                    </Typography>

                                    <Typography
                                        component="div"
                                        variant="h5"
                                        sx={{
                                            fontFamily: "'Poppins'",
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: { sm: '18px', xs: '15px' },
                                            lineHeight: '27px',
                                            letterSpacing: '0.02em',
                                            color: '#94959B',
                                            textAlign: 'center',
                                            marginTop: '11px'
                                        }}
                                    >
                                        Do you already have an account? {' '}
                                        <Link
                                            onClick={() =>
                                                navigate(
                                                    ROUTES.LOGIN +
                                                    (callback !== null
                                                        ? '?callback=' + encodeURIComponent(callback)
                                                        : '')
                                                )
                                            }
                                            sx={{
                                                fontFamily: "'Poppins'",
                                                fontStyle: 'normal',
                                                fontWeight: '500',
                                                cursor: 'pointer',
                                                fontSize: { sm: '18px', xs: '15px' },
                                                lineHeight: '27px',
                                                letterSpacing: '0.02em',
                                                color: '#3865F3'
                                            }}
                                            component={'span'}
                                        >
                                            Login
                                        </Link>
                                    </Typography>
                                </Box>

                                {/* ####################################################################### */}
                                {/* ________________________ Complete Sign Up Form ________________________ */}
                                {/* ####################################################################### */}

                                <form onSubmit={handleSignUpWithEmail}>
                                    {/* Input Box */}
                                    <Typography
                                        component='h3'
                                        textAlign='center'
                                        fontSize='20px'
                                        fontWeight='bold'
                                        marginBottom='8px'
                                        sx={{
                                            marginTop: { sm: '30px', xs: '15px' },
                                            marginX: '20px'
                                        }}
                                    >
                                        Memberships
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            marginX: '20px'
                                        }}
                                    >
                                        <ShowMemberships value={membership} setValue={setMembership} />
                                    </Box>
                                    <CustomInput
                                        placeHolder={'Full Name'}
                                        type="text"
                                        value={name}
                                        name="fullName"
                                        autoComplete="name"
                                        onChange={(e) => setName(e.target.value)}
                                        required={true}
                                        Icon={<PersonIcon />}
                                        InputStyles={{
                                            background: '#F6F6F6',
                                            borderRadius: '12px',
                                            marginTop: { sm: '45px', xs: '25px' }
                                        }}
                                    />
                                    <CustomInput
                                        placeHolder={'User Name'}
                                        type="text"
                                        value={username}
                                        name="userName"
                                        autoComplete="username"
                                        onChange={(e) => setUsername(e.target.value)}
                                        required={true}
                                        Icon={<AccountCircleIcon />}
                                        InputStyles={{
                                            background: '#F6F6F6',
                                            borderRadius: '12px',
                                            marginTop: '16px'
                                        }}
                                    />
                                    <CustomInput
                                        placeHolder={'Your Email'}
                                        type="email"
                                        value={email}
                                        name="email"
                                        autoComplete="new-email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        required={true}
                                        Icon={<EmailOutlinedIcon />}
                                        InputStyles={{
                                            background: '#F6F6F6',
                                            borderRadius: '12px',
                                            marginTop: '16px'
                                        }}
                                    />
                                    <CustomInput
                                        placeHolder={'Password'}
                                        type="password"
                                        value={password}
                                        name="password"
                                        autoComplete="new-password"
                                        onChange={(e) => setPassword(e.target.value)}
                                        required={true}
                                        Icon={<LockOutlinedIcon />}
                                        InputStyles={{
                                            background: '#F6F6F6',
                                            borderRadius: '12px',
                                            marginTop: '16px'
                                        }}
                                    />
                                    <CustomInput
                                        placeHolder={'Confirm Password'}
                                        type="password"
                                        name="confirmPassword"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        required={true}
                                        Icon={<LockOutlinedIcon />}
                                        InputStyles={{
                                            background: '#F6F6F6',
                                            borderRadius: '12px',
                                            marginTop: '16px',
                                            marginBottom: { sm: '25px', xs: '10px' }
                                        }}
                                    />
                                    {/* Input Box */}

                                    {/* Login Button */}
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            height: { sm: '48px', xs: '35px' },
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            fontSize: { sm: '18px', xs: '14px' },
                                            lineHeight: '27px',
                                            letterSpacing: '0.02em',
                                            color: '#FFFFFF',
                                            background: '#3865F3',
                                            borderRadius: '12px',
                                            width: '100%',
                                            textTransform: 'none',
                                            mt: '13px'
                                        }}
                                    >
                                        Sign Up
                                    </Button>
                                    {/* Login Button */}
                                </form>

                                {/* social icons section */}
                                {/* <SocialAuth userType={userType} callback={callback} /> */}

                                {/* social icons section */}
                                {/* ####################################################################### */}
                                {/* ________________________ Complete Sign Up Form ________________________ */}
                                {/* ####################################################################### */}
                            </Box>
                        )}
                    </Box>

                    <br />
                    <br />
                </Box>
                <br />
                <br />
                <br />
            </Box>
        </Box>
    )
}
export default SignUp
