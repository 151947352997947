import { useState, useEffect, FC, MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'

import { useMediaQuery, Box, Modal } from '@mui/material'

import theme from '../../theme'

// Importing firebase
import { auth } from '../../firebase'
import { signOut, User } from 'firebase/auth'
import { ROUTES } from '../../constants/routes'
import { useAuthState } from 'react-firebase-hooks/auth'
import MobileHeader from './MobileHeader'
import DesktopHeader from './DesktopHeader'

const Header: FC = () => {
    const [user, userLoading] = useAuthState(auth)
    const navigate = useNavigate()

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleAvatarClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    // signed in user data
    const [signedInUserData, setSignedInUserData] = useState<User | null | undefined>(null)
    const [Loading, setloading] = useState(true)

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname])

    useEffect(() => {
        if (userLoading) return
        if (user !== null) {
            if (signedInUserData === null) {
                setSignedInUserData(user)
                setloading(false)
            }
        }
    }, [signedInUserData, user, userLoading])

    const logoutUser = () => {
        if (window.confirm('Are you sure you want to logout?') && !Loading) {
            signOut(auth)
                .then(() => {
                    // alert("You have been signed out successfully");
                    navigate(ROUTES.LOGIN)
                })
                .catch((error) => {
                    // An error happened.
                    // alert(`Error Logging Out : ${error.message}`);
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    console.log(`Error Logging out==> ${error}`)
                })
        }
    }

    return (
        <>
            {!isMobile
                ? (
                    <DesktopHeader
                        signedInUserData={signedInUserData}
                        Loading={Loading}
                        user={user}
                        userLoading={userLoading}
                        anchorEl={anchorEl}
                        open={open}
                        logoutUser={logoutUser}
                        handleClose={handleClose}
                        navigate={navigate}
                        handleAvatarClick={handleAvatarClick}
                    />
                )
                : (
                    <MobileHeader
                        signedInUserData={signedInUserData}
                        Loading={Loading}
                        userLoading={userLoading}
                        user={user}
                        navigate={navigate}
                        handleAvatarClick={handleAvatarClick}
                        anchorEl={anchorEl}
                        open={open}
                        logoutUser={logoutUser}
                        handleClose={handleClose}
                    />
                )}
            {/* To cover the height of fixed navbar */}
            {isMobile ? <Box sx={{ backgroundColor: '#2d3345', height: '60px' }} /> : <Box height="70px" />}
        </>
    )
}
export default Header
